/*
 * Ryan O'Dowd
 * 2019-01-08
 * © Copyright 2018 NursingABC, Inc.  All Rights Reserved.
 */
import {
  Card,
  CardContent,
} from '@mui/material';
import PropTypes from 'prop-types';
import styles from './styles';
import {
  useNavigate,
} from 'react-router-dom';

const HomeCard = (props) => {
  const navigate = useNavigate();

  return (
    <Card
      style={{...styles.container, ...(props.isAlphaFeature ? styles.alphaFeature : {})}}
      onClick={() => {
        if (props.href.startsWith('https://')) {
          window.location = props.href;
        } else {
          navigate(props.href.split('/:')[0]);
        }
      }}
    >
      <CardContent style={styles.cardContent}>
        <h3 style={styles.name}>{props.icon}&nbsp;&nbsp;{props.name}</h3>
        <p style={styles.description}>{props.description}</p>
      </CardContent>
    </Card>
  );
};

HomeCard.propTypes = {
  description: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  icon: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,

  isAlphaFeature: PropTypes.bool,
};

export default HomeCard;

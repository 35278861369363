/*
 * Ally Zernick
 * 2024-04-24
 * © Copyright 2024 NursingABC, Inc.  All Rights Reserved.
 */
import {
  AppBar,
  Button,
  CircularProgress,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Snackbar,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  BrowserRouter,
  Link,
  Route,
  Routes,
} from 'react-router-dom';
import {
  FETCH_ERROR_OBJECT,
  FETCH_SUCCESS_OBJECT,
  fetchCurrentAdmin,
  removeFetch,
  updateFetch,
} from '../../actions';
import React, {
  useEffect,
  useState,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import AcademicScholarships from '../AcademicScholarships';
import Account from '../Account';
import Admins from '../Admins';
import Alert from '@mui/material/Alert';
import AwsTools from '../DeveloperTools/AwsTools';
import CheckIcon from 'mdi-react/CheckIcon';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
/* import ChristmasLights from '../ChristmasLights'; */
import CloseIcon from 'mdi-react/CloseIcon';
import CompanyDashboard from '../CompanyDashboard';
import DatabaseTools from '../DeveloperTools/DatabaseTools';
import DiscountCodes from '../DiscountCodes';
import ElectronicTranscriptTransfer from '../ElectronicTranscriptTransfer';
import Error404 from '../HiddenPages/Error404';
import ExportOutcomes from '../ExportOutcomes';
import Faculty from '../Faculty';
import FacultyDashboard from '../Faculty/Dashboard';
import Footer from '../Footer';
import Globals from '../../Globals';
import GradeChangeOpenReviewReport from '../Reporting/GradeChangeOpenReview';
import Header from './Header';
import Home from '../Home';
import InstructorAssignment from '../InstructorAssignment';
import IntegrityViolations from '../IntegrityViolations';
import ListItemIcon from '@mui/material/ListItemIcon';
import LowGradeWarnings from '../LowGradeWarnings';
import MenuIcon from '@mui/icons-material/Menu';
import MergeStudents from '../MergeStudents';
import NewContacts from '../Contacts';
import PartnerAgreements from '../PartnerAgreements';
import PartnerSchools from '../PartnerSchools';
import PartnerStudentImport from '../PartnerStudentImport';
import Payroll from '../Payroll';
import Refunds from '../Refunds';
import Registrations from '../Registrations';
import Reporting from '../Reporting';
import SectionCreation from '../SectionCreation';
import SecureFiles from '../SecureFiles';
import StudentManagement from '../StudentManagement';
import StudentProgress from '../StudentProgress';
import Syllabi from '../Syllabi';
import Terms from '../Terms';
import Transcripts from '../Transcripts';
import VacationCoverage from '../VacationCoverage';
import commonStyles from '../../commonStyles';
import {
  fetchAuthSession,
} from 'aws-amplify/auth';
import homeOptions from '../Home/homeOptions';
import styles from './styles';
import {
  withAuthenticator,
} from '@aws-amplify/ui-react';

const App = () => {
  const [_drawerIsOpen, setDrawerIsOpen] = useState(false);
  const admin = useSelector((state) => state.admin);
  const loadingStates = useSelector((state) => state.loadingStates);
  const fetches = useSelector((state) => state.fetches);
  const dispatch = useDispatch();

  useEffect(() => {
    async function saveJwt() {
      try {
        fetchAuthSession().then((token) => {
          localStorage.setItem('jwt', token.tokens.accessToken.toString());
          dispatch(fetchCurrentAdmin());
        });
      } catch (e) {
        console.log(e);
      }
    }
    if (admin && !admin.perms.developer) {
      removeFetches();
    }
    saveJwt();
  }, []);

  const removeFetches = () => {
    Object.values(fetches).forEach((fetchCall) => {
      dispatch(removeFetch(fetchCall.requestId));
    });
  };

  if (!admin) {
    return null;
  }

  const renderToast = () => {
    const fetchRequest = Object.values(fetches).find((fetchRequest) => !!fetchRequest.message && !fetchRequest.isRead);
    if (!fetchRequest) {
      return null;
    }

    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }

      if (fetchRequest.status !== FETCH_ERROR_OBJECT.status) {
        dispatch(removeFetch(fetchRequest.requestId));
      } else {
        dispatch(updateFetch({...fetchRequest, isRead: true}));
      }
    };

    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={true}
        autoHideDuration={5000}
        onClose={handleClose}
      >
        <Alert severity={fetchRequest.isSuccessfullFetch ? 'success' : 'error'} variant='standard'>
          <div style={styles.alertContainer}>
            <p id='message-id'>{fetchRequest.message}</p>
            <IconButton
              key='close'
              aria-label='close'
              color='inherit'
              onClick={handleClose}
              size='large'
            >
              <CloseIcon />
            </IconButton>
          </div>
        </Alert>
      </Snackbar>
    );
  };

  return (
    <>
      <Header />
      <div style={styles.container}>
        <BrowserRouter>
          <div>
            <AppBar
              style={styles.appBar}
              position='fixed'
            >
              <Toolbar disableGutters={!_drawerIsOpen}>
                <IconButton
                  color='inherit'
                  aria-label='Open drawer'
                  onClick={() => setDrawerIsOpen(!_drawerIsOpen)}
                  style={{...styles.menuButton, ...(_drawerIsOpen ? styles.hide : {})}}
                  size='large'
                >
                  <MenuIcon />
                </IconButton>
                <Typography style={styles.appBarTitle} variant='h6' color='inherit'>
                  {/* @TODO: only underline on hover, and keep white */}
                  <Link style={styles.homeLink} to={'/'}>Admin Panel</Link>
                </Typography>
                <div style={styles.spacer}></div>
                {
                  <>
                    <div style={admin.perms.developer ? styles.fetches : null}>
                      {!admin.perms.developer &&
                        Object.values(loadingStates).some((loadingState) => !!loadingState) && <CircularProgress color={Globals.colors.light} size={30} />
                      }
                      {/* due to `direction: rtl`, the scrolling now starts from the right, so this array needs to be reversed to allow newest fetches to be the right-most items */}
                      {!!admin.perms.developer &&
                        Object.values(fetches).reverse().map((fetchCall) => {
                          let icon = <CircularProgress style={styles.fetchIcon} color='inherit' size={20} />;
                          if (fetchCall.status === FETCH_SUCCESS_OBJECT.status) {
                            icon = <CheckIcon style={styles.fetchIcon} color={Globals.colors.light} />;
                          } else if (fetchCall.status === FETCH_ERROR_OBJECT.status) {
                            icon = <CloseIcon style={styles.fetchIcon} color={Globals.colors.light} />;
                          }

                          return (
                            <Tooltip
                              key={fetchCall.requestId}
                              title={`${fetchCall.endpoint}${fetchCall.message ? `: ${fetchCall.message}` : ''}`}
                            >
                              <span>{icon}</span>
                            </Tooltip>
                          );
                        })}
                    </div>
                    {!!admin.perms.developer &&
                      <Button
                        color='inherit'
                        onClick={() => {
                          removeFetches();
                        }}
                      >
                        Clear
                      </Button>
                    }
                  </>
                }
              </Toolbar>
            </AppBar>
            <Drawer
              style={styles.drawer}
              anchor='left'
              open={_drawerIsOpen}
              onClose={() => setDrawerIsOpen(!_drawerIsOpen)}
            >
              <div style={styles.drawerHeader}>
                <IconButton onClick={() => setDrawerIsOpen(!_drawerIsOpen)} size='large'>
                  {_drawerIsOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                </IconButton>
              </div>
              <List>
                {homeOptions(admin.perms).filter((homeOption) => !homeOption.hideInSidebar).sort((a, b) => a.name.localeCompare(b.name)).map((homeOption) => {
                  let thisProps = {
                    component: Link,
                    to: homeOption.href.split('/:')[0],
                  };
                  if (homeOption.href.startsWith('https://')) {
                    thisProps = {
                      component: 'a',
                      href: homeOption.href,
                    };
                  }
                  return (
                    <React.Fragment key={homeOption.name}>
                      {homeOption.startOfNewSection && <Divider />}
                      <ListItem
                        button
                        onClick={() => setDrawerIsOpen(!_drawerIsOpen)}
                        {...thisProps}
                      >
                        <ListItemIcon>{homeOption.icon}</ListItemIcon>
                        <ListItemText primary={homeOption.name} />
                      </ListItem>
                    </React.Fragment>
                  );
                })}
              </List>
            </Drawer>
            {/* <ChristmasLights /> */}
            {_drawerIsOpen && <div style={styles.overlay}></div>}
            <main style={styles.content}>
              <div style={styles.innerContainer}>
                <div style={commonStyles.paddedContainer}>
                  <Routes>
                    <Route path='/students' element={<StudentManagement />} />
                    <Route path='/students/:id' element={<StudentManagement />} />
                    <Route path='/students/merge' element={<MergeStudents />} />
                    <Route path='/authorization_codes' element={<DiscountCodes />} />
                    <Route path='/registrations' element={<Registrations />} />
                    <Route path='/transcripts' element={<Transcripts />} />
                    <Route path='/electronic_transcript_transfer' element={<ElectronicTranscriptTransfer />} />
                    <Route path='/secure_files' element={<SecureFiles />} />
                    <Route path='/student_progress' element={<StudentProgress />} />
                    <Route path='/' element={<Home />} />
                    <Route path='/instructor_assignment' element={<InstructorAssignment />} />
                    <Route path='/syllabi' element={<Syllabi />} />
                    <Route path='/export_outcomes' element={<ExportOutcomes />} />
                    <Route path='/low_grade_warnings' element={<LowGradeWarnings />} />
                    <Route path='/integrity_violations' element={<IntegrityViolations />} />
                    <Route path='/partner_student_import' element={<PartnerStudentImport />} />
                    <Route path='/terms' element={<Terms />} />
                    <Route path='/payroll' element={<Payroll />} />
                    <Route path='/refunds' element={<Refunds />} />
                    <Route path='/new_contacts' element={<NewContacts />} />
                    <Route path='/partner_schools' element={<PartnerSchools />} />
                    <Route path='/admins' element={<Admins />} />
                    <Route path='/grade_change_open_for_review' element={<GradeChangeOpenReviewReport />} />
                    <Route path='/authorization_codes' element={<DiscountCodes />} />
                    <Route path='/developer_tools/aws' element={<AwsTools />} />
                    <Route path='/developer_tools/database' element={<DatabaseTools />} />
                    <Route path='/company_dashboard' element={<CompanyDashboard />} />
                    <Route path='/reporting' element={<Reporting />} />
                    <Route path='/account' element={<Account />} />
                    <Route path='/academic_scholarships' element={<AcademicScholarships />} />
                    <Route path='/create_sections' element={<SectionCreation />} />
                    <Route path='/vacation_coverage' element={<VacationCoverage />} />
                    <Route path='/partner_agreements' element={<PartnerAgreements />} />
                    <Route path='/faculty' element={<Faculty />} />
                    <Route path='/faculty_dashboard' element={<FacultyDashboard />} />
                    <Route path='*' exact={true} element={<Error404 />} />
                  </Routes>
                </div>
              </div>
            </main>
          </div>
        </BrowserRouter>
        {renderToast()}
        <Footer />
      </div>
    </>);
};

export default withAuthenticator(App);

/*
 * Ally Zernick
 * 2022-10-20
 * © Copyright 2018 NursingABC, Inc.  All Rights Reserved.
 */
import Globals from '../../Globals';

const styles = {
  checkboxes: {
    display: 'grid',
    gridTemplateColumns: 'auto auto auto',
  },
  filters: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 15,
  },
  DisplayReportDatabuttonStyle: {
    marginTop: 8,
  },
  selectedStudentRow: {
    backgroundColor: Globals.colors.lightGray,
  },
  detailsRow: {
    display: 'flex',
    flexDirection: 'row',
    marginRight: '1%',
  },
  buttonStyle: {
    width: 'fit-content',
    marginTop: '1%',
  },
  textField: {
    margin: 8,
  },
  detailsLong: {
    width: '15%',
    fontSize: '0.6rem',
  },
  details: {
    width: '7.15%',
    fontSize: '0.6rem',
  },
  lineSeparator: {
    width: '100%',
    height: 1,
    backgroundColor: 'lightgrey',
    marginBottom: '2%',
    marginTop: '2%',
  },
  editIcon: {
    padding: 0,
    margin: 0,
    height: '10px',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    width: '30%',
    margin: '1%',
  },
  multiColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    margin: '1%',
    gap: 20,
  },
  searchBox: {
    marginRight: 15,
    marginBottom: 7,
  },
};

export default styles;

/*
 * Nick Lewyn
 * 2020-01-27
 * © Copyright 2020 NursingABC, Inc.  All Rights Reserved.
 */
import {
  RSAA,
} from 'redux-api-middleware';
import dayjs from 'dayjs';
import {
  decodeJwt,
} from '../utilities';
import {
  fetchAuthSession,
} from 'aws-amplify/auth';
import {
  logout,
} from '../actions';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

const apiAuthorizationMiddleware = () => (next) => (action) => {
  // pass to next middleware if not a redux-api-middleware action or token is not expired
  if (!action[RSAA] || !_isTokenExpired()) {
    return next(action);
  }

  return fetchAuthSession().then((newSession) => {
    localStorage.setItem('jwt', newSession.tokens.accessToken.toString());
    action[RSAA].headers.Authorization = localStorage.getItem('jwt');
    return next(action);
  }).catch((error) => {
    console.error(`An error occurred when trying to refresh session - code: ${error.code}; Name: ${error.name}; Message: ${error.message}`);
    logout();
  });
};

function _isTokenExpired() {
  const jwt = decodeJwt(localStorage.getItem('jwt'));
  const currTime = dayjs().utc().valueOf();
  const jwtExpTime = dayjs(jwt.exp * 1000).valueOf();
  return (currTime > jwtExpTime);
}

export default apiAuthorizationMiddleware;

/*
 * Ryan O'Dowd
 * 2019-04-10
 * © Copyright 2024 NursingABC, Inc.  All Rights Reserved.
 */
import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import styles from './styles';
import {
  useSelector,
} from 'react-redux';

dayjs.extend(localizedFormat);

const GRADED = 'graded';
const PENDING_REVIEW = 'pending_review';

const Grades = (props) => {
  const grades = useSelector((state) => state.grades[props.studentId]);

  // @TODO: remove this function?
  const _getGradeColor = (submission) => {
    if (submission.assignment_status === 'unsubmitted') {
      return 'initial';
    } else if (submission.assignment_status === 'submitted') {
      return 'red';
    } else if (submission.assignment_status === PENDING_REVIEW) {
      return 'orange';
    } else if (submission.assignment_status === GRADED) {
      return 'green';
    }

    return 'initial';
  };

  const course = grades[props.enrollmentId];
  if (!course) {
    return <div><CircularProgress color='secondary' /></div>;
  }

  const totalPointsCurrent = course.grades.reduce((acc, assignment) => +acc + (+assignment.points_earned || 0), 0);
  const totalPointsPossible = course.grades.reduce((acc, assignment) => +acc + (+assignment.points_possible || 0), 0);
  const totalPointsProgressSoFar = course.grades.filter((assignment) => [GRADED, PENDING_REVIEW].includes(assignment.assignment_status)).reduce((acc, assignment) => +acc + (+assignment.points_possible || 0), 0);
  const totalPointsPossibleSoFar = course.grades.filter((assignment) => [GRADED].includes(assignment.assignment_status)).reduce((acc, assignment) => +acc + (+assignment.points_possible || 0), 0);
  return (
    <div style={styles.container}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Assignment</TableCell>
            <TableCell align='right'>Date completed</TableCell>
            <TableCell align='right'>Date graded</TableCell>
            {!props.hideActualGrades && <TableCell align='right'>Points</TableCell>}
            {!props.hideActualGrades && <TableCell align='right'>Grade</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {/* @TODO: add tooltips to all these rows */}
          {/* @TODO: sort by any column on click */}
          {course.grades.map((assignment) => {
            return (
              <TableRow key={assignment.assignment_id}>
                <TableCell style={styles.cellStyle} component='th' scope='row'>{assignment.assignment_name}</TableCell>{/* @TODO: need component and scope? what about other cells? */}
                <TableCell style={styles.cellStyle} align='right'>
                  {assignment.submitted_at
                    ? (
                      <Tooltip title={dayjs(assignment.submitted_at).format('LT')}>
                        <span>{dayjs(assignment.submitted_at).format('L')}</span>
                      </Tooltip>
                    ) : '-'
                  }
                </TableCell>
                <TableCell style={{...styles.cellStyle, color: _getGradeColor(assignment)}} align='right'>
                  {(assignment.assignment_status === GRADED && assignment.graded_at)
                    ? (
                      <Tooltip title={dayjs(assignment.graded_at).format('LT')}>
                        <span>{dayjs(assignment.graded_at).format('L')}</span>
                      </Tooltip>
                    ) : '-'
                  }
                </TableCell>
                {/* @TODO: allow for zeros of 0...it's falsey but not null */}
                {!props.hideActualGrades &&
                  <>
                    <TableCell style={{...styles.cellStyle, color: _getGradeColor(assignment)}} align='right'>{`${(assignment.assignment_status === GRADED && assignment.points_earned >= 0) ? assignment.points_earned : '-'}/${assignment.points_possible}`}</TableCell>
                    <TableCell style={styles.cellStyle} align='right'>{(assignment.assignment_status === GRADED && assignment.points_earned >= 0 && assignment.points_possible !== 0) ? `${assignment.percentage}% ${assignment.letter_grade}` : '-'}</TableCell>
                  </>
                }
              </TableRow>
            );
          })}
          {!props.hideActualGrades &&
            <TableRow style={styles.totalRow} key='totals'>
              <TableCell style={styles.totalRowCell} colSpan={3} component='th' scope='row'>
                Current total
                <br />
                Final total
                <br />
                <br />
                Course progress
              </TableCell>{/* @TODO: need component and scope? what about other cells? */}
              <TableCell style={styles.totalRowCell} align='right'>
                {/* @TODO: decimals should be truncated at 2 places, not rounded */}
                {`${totalPointsCurrent.toFixed(2) || '-'}/${totalPointsPossibleSoFar}`}
                <br />
                {`${totalPointsCurrent.toFixed(2) || '-'}/${totalPointsPossible}`}
                <br />
                <br />
                {`${totalPointsProgressSoFar}/${totalPointsPossible}`}
              </TableCell>
              <TableCell style={styles.totalRowCell} align='right'>
                {course.current_grade_percentage ? `${course.current_grade_percentage}% ${course.current_grade}` : '-'}
                <br />
                {course.final_grade_percentage ? `${course.final_grade_percentage}% ${course.final_grade}` : '-'}
                <br />
                <br />
                {`${course.course_progress}%`}
              </TableCell>
            </TableRow>
          }
        </TableBody>
      </Table>
    </div>
  );
};

Grades.propTypes = {
  enrollmentId: PropTypes.number.isRequired,
  studentId: PropTypes.number.isRequired,

  hideActualGrades: PropTypes.bool,
};

export default Grades;

/*
 * Ally Zernick
 * 2024-07-26
 * © Copyright 2024 NursingABC, Inc.  All Rights Reserved.
 */
import Globals from '../../Globals';

const styles = {
  schoolContainer: {
    display: 'flex',
    gap: '10px',
  },
  campusInput: {
    minWidth: '300px',
    width: 'fit-content',
    marginBottom: '10px',
  },
  campusContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '10px',
  },
  bottomSpacing: {
    marginBottom: '10px',
  },
  verticalSpacing: {
    margin: '50px 0px',
  },
  errorText: {
    color: Globals.colors.error,
    fontWeight: 'bold',
  },
  createCampusButton: {
    width: 'max-content',
    marginLeft: 'auto',
  },
  largeBottomSpacing: {
    marginBottom: '100px',
  },
  quarterWidth: {
    width: '25%',
  },
  search: {
    margin: '10px',
    marginBottom: '0px',
  },
  iconCell: {
    display: 'flex',
    alignItems: 'center',
  },
  dialogEditCell: {
    minWidth: '100%',
  },
  tableTitle: {
    display: 'flex',
    marginLeft: '10px',
    alignItems: 'center',
  },
  stateLabel: {
    marginTop: '-14px',
    backgroundColor: 'white',
    fontSize: '12px',
    left: 14,
    padding: '0px 5px',
    position: 'relative',
    top: 8,
    width: 'fit-content',
    zIndex: 10,
  },
  dialogSwitches: {
    marginTop: '20px',
  },
  dialogSwitch: {
    marginBottom: 0,
    maxHeight: '30px',
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  },
  institutionName: {
    margin: '5px',
    width: '100%',
  },
};

export default styles;

/*
 * Ryan O'Dowd
 * 2020-10-06
 * © Copyright 2020 NursingABC, Inc.  All Rights Reserved.
 */
import Globals from '../../../Globals';

const DIALOG_CONTENT_WIDTH = 600;

const styles = {
  expand: {
    flexGrow: 1,
    marginTop: '20px',
  },
  innerDialogContent: {
    width: DIALOG_CONTENT_WIDTH - 50,
  },
  textField: {
    marginRight: 8,
  },
  stateSelectField: {
    width: 80,
    marginRight: 8,
  },
  errorText: {
    color: Globals.colors.error,
  },
  formRow: {
    display: 'flex',
  },
  subtitle: {
    fontSize: '0.75rem',
  },
  noMarginBottom: {
    marginBottom: 0,
  },
  datePicker: {
    display: 'none',
  },
};

export default styles;

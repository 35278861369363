/*
 * Ally Zernick
 * 2022-10-20
 * © Copyright 2024 NursingABC, Inc.  All Rights Reserved.
 */

const styles = {
  selectors: {
    width: '20%',
    marginRight: 10,
  },
  noteText: {
    color: 'gray',
    paddingBottom: '15px',
    marginLeft: '15px',
  },
  download: {
    marginTop: '10px',
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
    color: 'gray',
  },
  chip: {
    marginLeft: '5px',
    marginBottom: '-1px',
    maxHeight: '18px',
  },
  simpleMargin: {
    margin: '10px 10px 0px',
  },
  statsContainer: {
    marginTop: '20px',
    padding: '10px',
  },
  selectHeader: {
    display: 'flex',
    alignItems: 'center',
  },
};

export default styles;

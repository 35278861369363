/*
 * Ryan O'Dowd
 * 2019-01-21
 * © Copyright 2018 NursingABC, Inc.  All Rights Reserved.
 */
import Globals from '../../Globals';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'right',
    padding: 16,
    borderTopStyle: 'solid',
    borderTopWidth: '4',
    borderTopColor: Globals.colors.mediumGray,
    backgroundColor: Globals.colors.darkGray,
    color: Globals.colors.light,
  },
};

export default styles;

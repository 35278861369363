/*
 * Ryan O'Dowd
 * 2019-01-15
 * © Copyright 2024 NursingABC, Inc.  All Rights Reserved.
 */
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  Button,
} from '@mui/material';
import {
  logout,
} from '../../actions';

const Account = () => {
  const dispatch = useDispatch();
  const admin = useSelector((state) => state.admin);

  if (!admin) {
    return null;
  }

  return (
    <div>
      <h3>Account</h3>
      <p>{`You currently are logged in as ${admin.first_name} ${admin.last_name} (${admin.email}).`}</p>
      <Button
        variant='contained'
        color='primary'
        onClick={() => dispatch(logout())}
      >
        Logout
      </Button>
    </div>
  );
};

export default Account;

/*
 * Ryan O'Dowd
 * 2019-11-21
 * © Copyright 2024 NursingABC, Inc.  All Rights Reserved.
 */
import './styles.css';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid2 as Grid,
  IconButton,
  Paper,
  TextField,
  Tooltip,
} from '@mui/material';
import {
  clearApiMergeStudentFlag,
  fetchStudent,
  fetchStudentCourses,
  mergeStudents,
  updateStudent,
} from '../../actions';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  useEffect,
  useState,
} from 'react';
import Alert from '@mui/material/Alert';
import Details from '../StudentManagement/Details';
import RefreshIcon from 'mdi-react/RefreshIcon';
import SwapHorizontalIcon from 'mdi-react/SwapHorizontalIcon';
import dayjs from 'dayjs';
import {
  getFormattedPhone,
} from '../../utilities';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import styles from './styles';
import {
  useSearchParams,
} from 'react-router-dom';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(localizedFormat);

const DISABLED_NOTES = 'Temporarily disabled while Student Services investigates account.';

const MergeStudents = () => {
  const [_sourceStudentIdOrEmailText, setSourceStudentIdOrEmailText] = useState('');
  const [_destinationStudentIdOrEmailText, setDestinationStudentIdOrEmailText] = useState('');
  const [_sourceStudentId, setSourceStudentId] = useState(null);
  const [_destinationStudentId, setDestinationStudentId] = useState(null);
  const [_keepSourceAddress, setKeepSourceAddress] = useState(true);
  const [_keepSourceBirthday, setKeepSourceBirthday] = useState(true);
  const [_keepSourceEmail, setKeepSourceEmail] = useState(true);
  const [_keepSourceName, setKeepSourceName] = useState(true);
  const [_keepSourcePhone, setKeepSourcePhone] = useState(true);
  const [_disabledStudentIds, setDisabledStudentIds] = useState([]);
  const [_confirmStudentMergeDialogIsOpen, setConfirmStudentMergeDialogIsOpen] = useState(false);
  const [_selectedFields, setSelectedFields] = useState({
    name: true, email: true, phone: true, birthday: true, address: true,
  });

  const students = useSelector((state) => state.students);
  const apiMergeStudent = useSelector((state) => state.apiMergeStudent);
  const studentCourses = useSelector((state) => state.studentCourses);
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();

  useEffect(() => {
    const sourceStudent = searchParams.get('initial_source_student_id');
    const destinationStudent = searchParams.get('initial_destination_student_id');

    setSourceStudentIdOrEmailText(sourceStudent);
    setDestinationStudentIdOrEmailText(destinationStudent);

    if (sourceStudent) {
      dispatch(fetchStudent(sourceStudent));
    }
    if (destinationStudent) {
      dispatch(fetchStudent(destinationStudent));
    }
  }, []);

  useEffect(() => {
    const sourceStudent = _getStudent(students, _sourceStudentIdOrEmailText);
    const destinationStudent = _getStudent(students, _destinationStudentIdOrEmailText);
    if (sourceStudent) {
      dispatch(fetchStudentCourses(sourceStudent.id));
    }
    if (destinationStudent) {
      dispatch(fetchStudentCourses(destinationStudent.id));
    }
    setSourceStudentId(sourceStudent ? sourceStudent.id : null);
    setDestinationStudentId(destinationStudent ? destinationStudent.id : null);
  }, [students]);

  useEffect(() => {
    if (apiMergeStudent.merged) {
      setSourceStudentIdOrEmailText('');
      setDestinationStudentIdOrEmailText('');
      setSourceStudentId(null);
      setDestinationStudentId(null);

      dispatch(clearApiMergeStudentFlag());
    }
  }, [apiMergeStudent]);

  const _getStudent = (students, idOrEmail) => {
    return students[idOrEmail] || Object.values(students).find((u) => u.email === idOrEmail);
  };

  const _getFormattedAddress = (student) => {
    const addressLine2 = student.line_2 ? `${student.line_2}\n` : '';
    const country = student.country ? `, ${student.country}` : '';

    return `${student.line_1}\n${addressLine2}${student.city}, ${student.state} ${student.zip_code}${country}`;
  };

  const _getFormattedName = (student) => {
    if (student) {
      const suffix = student.suffix ? ` ${student.suffix}` : '';
      const middleName = student.middle_name ? ` ${student.middle_name} ` : ' ';

      return `${student.first_name}${middleName}${student.last_name}${suffix}`;
    } else {
      return null;
    }
  };

  const _getFormattedBirthday = (student) => {
    return dayjs(student.birthday).utc().format('LL');
  };

  const _renderStudent = (student, isSourceStudent) => {
    const sourceStudent = students[_sourceStudentId];
    const destinationStudent = students[_destinationStudentId];

    let nameClass = '';
    let emailClass = '';
    let phoneClass = '';
    let birthdayClass = '';
    let addressClass = '';
    if (student && sourceStudent && destinationStudent) {
      nameClass = !(isSourceStudent ^ _keepSourceName) ? 'selected' : '';
      if (_getFormattedName(sourceStudent) === _getFormattedName(destinationStudent)) {
        nameClass = `${nameClass} sameValue`;
      } else {
        nameClass = isSourceStudent === _selectedFields.name ? 'selected' : '';
      }
      emailClass = !(isSourceStudent ^ _keepSourceEmail) ? 'selected' : '';
      if (sourceStudent.email === destinationStudent.email) {
        emailClass = `${emailClass} sameValue`;
      } else {
        emailClass = isSourceStudent === _selectedFields.email ? 'selected' : '';
      }
      phoneClass = !(isSourceStudent ^ _keepSourcePhone) ? 'selected' : '';
      if (getFormattedPhone(sourceStudent.phone) === getFormattedPhone(destinationStudent.phone)) {
        phoneClass = `${phoneClass} sameValue`;
      } else {
        phoneClass = isSourceStudent === _selectedFields.phone ? 'selected' : '';
      }
      birthdayClass = !(isSourceStudent ^ _keepSourceBirthday) ? 'selected' : '';
      if (_getFormattedBirthday(sourceStudent) === _getFormattedBirthday(destinationStudent)) {
        birthdayClass = `${birthdayClass} sameValue`;
      } else {
        birthdayClass = isSourceStudent === _selectedFields.birthday ? 'selected' : '';
      }
      addressClass = !(isSourceStudent ^ _keepSourceAddress) ? 'selected' : '';
      if (_getFormattedAddress(sourceStudent) === _getFormattedAddress(destinationStudent)) {
        addressClass = `${addressClass} sameValue`;
      } else {
        addressClass = isSourceStudent === _selectedFields.address ? 'selected' : '';
      }
    }

    return (<>
      <div style={styles.textFieldWrapper}>
        <TextField
          style={styles.textField}
          label={`${isSourceStudent ? 'Source' : 'Destination'} student ID or email`}
          onChange={(e) => isSourceStudent ? setSourceStudentIdOrEmailText(e.target.value) : setDestinationStudentIdOrEmailText(e.target.value)}
          margin='dense'
          required
        />
        <IconButton
          color='secondary'
          disabled={(isSourceStudent && !_sourceStudentIdOrEmailText) || (!isSourceStudent && !_destinationStudentIdOrEmailText)}
          onClick={() => dispatch(fetchStudent(isSourceStudent ? _sourceStudentIdOrEmailText : _destinationStudentIdOrEmailText))}
          size='large'
        >
          <RefreshIcon size={18} />
        </IconButton>
      </div>
      {student &&
        <>
          <div style={styles.row}>
            <TextField
              style={styles.nonSelectableTextField}
              label='ID'
              value={student.id}
              margin='dense'
              disabled
              variant='outlined'
            />
            <TextField
              style={styles.nonSelectableTextField}
              label='Account creation date'
              value={dayjs(student.created_at).format('LLL')}
              margin='dense'
              disabled
              variant='outlined'
            />
          </div>
          <div
            className={nameClass}
            onClick={() => {
              setKeepSourceName(isSourceStudent);
              setSelectedFields({..._selectedFields, name: isSourceStudent});
            }}
          >
            <TextField
              style={styles.selectableTextField}
              label='Name'
              value={_getFormattedName(student)}
              margin='dense'
              disabled
              color='secondary'
            />
          </div>
          <div
            className={emailClass}
            onClick={() => {
              setKeepSourceEmail(isSourceStudent);
              setSelectedFields({..._selectedFields, email: isSourceStudent});
            }}
          >
            <TextField
              style={styles.selectableTextField}
              label='Email'
              value={student.email}
              margin='dense'
              disabled
              variant='outlined'
              color='secondary'
            />
          </div>
          <div
            className={phoneClass}
            onClick={() => {
              setKeepSourcePhone(isSourceStudent);
              setSelectedFields({..._selectedFields, phone: isSourceStudent});
            }}
          >
            <TextField
              style={styles.selectableTextField}
              label='Phone number'
              value={getFormattedPhone(student.phone)}
              margin='dense'
              disabled
              variant='outlined'
              color='secondary'
            />
          </div>
          <div
            className={birthdayClass}
            onClick={() => {
              setKeepSourceBirthday(isSourceStudent);
              setSelectedFields({..._selectedFields});
            }}
          >
            <TextField
              style={styles.selectableTextField}
              label='Birthday'
              value={_getFormattedBirthday(student)}
              margin='dense'
              disabled
              variant='outlined'
              color='secondary'
            />
          </div>
          <div
            className={addressClass}
            onClick={() => {
              setKeepSourceAddress(isSourceStudent);
              setSelectedFields({..._selectedFields, address: isSourceStudent});
            }}
          >
            <TextField
              style={styles.selectableTextField}
              label='Address'
              multiline
              value={_getFormattedAddress(student)}
              margin='dense'
              disabled
              color='secondary'
            />
          </div>
          {_disabledStudentIds.includes(student.id)
            ? <p>{`${DISABLED_NOTES} Please be sure to have Engineering re-enable the account when this has been resolved.`}</p>
            : (student.disabled
              ? (
                <p>User disabled; will not be able to sign in.</p>
              ) : (
                <Tooltip title='Temporarily prevent student from signing in to provide more time to investigate the merged accounts.  Especially useful for cases where the new account has registered for a course that is currently active in the older account.'>
                  <Button
                    variant='outlined'
                    color='primary'
                    disabled={!_sourceStudentId || !_destinationStudentId}
                    onClick={() => {
                      dispatch(updateStudent({
                        id: student.id,
                        disabled: 1,
                        notes: DISABLED_NOTES,
                      }));
                      setDisabledStudentIds([..._disabledStudentIds, student.id]);
                    }}
                  >
                    Disable sign in
                  </Button>
                </Tooltip>
              )
            )
          }
          <div style={styles.detailsWrapper}>
            <Details
              studentId={student.id}
              studentCourses={studentCourses}
              renderCoursesOnly={true}
            />
          </div>
        </>
      }
    </>);
  };

  const sourceStudent = students[_sourceStudentId];
  const destinationStudent = students[_destinationStudentId];
  const sourceIdSmallerThanDestinationId = +_sourceStudentId < +_destinationStudentId;

  return (
    <div style={styles.container}>
      <h3>Merge Students</h3>
      <Paper style={styles.innerContainer}>
        <Grid container style={styles.mergeWrapper}>
          <Grid item style={styles.studentWrapper} xs={12} sm={5}>
            {_renderStudent(sourceStudent, true)}
          </Grid>
          <Grid item>
            <div style={styles.swapWrapper}>
              <IconButton
                color='secondary'
                disabled={!_sourceStudentIdOrEmailText && !_destinationStudentIdOrEmailText}
                onClick={() => {
                  const [newDestinationId, newSourceId] = [_sourceStudentId, _destinationStudentId];
                  const [newDestinationText, newSourceText] = [_sourceStudentIdOrEmailText, _destinationStudentIdOrEmailText];
                  // switch highlighted fields
                  Object.entries(_selectedFields).forEach(([key, value]) => {
                    _selectedFields[key] = !value;
                  });
                  setSourceStudentId(newSourceId);
                  setSourceStudentIdOrEmailText(newSourceText);
                  setDestinationStudentId(newDestinationId);
                  setDestinationStudentIdOrEmailText(newDestinationText);
                  setSelectedFields({..._selectedFields});
                }}
                size='large'
              >
                <SwapHorizontalIcon size={32} />
              </IconButton>
            </div>
          </Grid>
          <Grid item style={styles.studentWrapper}>
            {_renderStudent(destinationStudent, false)}
          </Grid>
        </Grid>
        <Button
          variant='contained'
          color='primary'
          disabled={!_sourceStudentId || !_destinationStudentId}
          onClick={() => setConfirmStudentMergeDialogIsOpen(!_confirmStudentMergeDialogIsOpen)}
        >
          Merge Students
        </Button>
        <Dialog
          open={_confirmStudentMergeDialogIsOpen}
          onClose={() => setConfirmStudentMergeDialogIsOpen(!_confirmStudentMergeDialogIsOpen)}
        >
          <DialogTitle id='alert-dialog-title'>Confirm merge</DialogTitle>
          <DialogContent>
            <p>Are you sure you want to merge these two students?  This will result in the following student:</p>
            {sourceIdSmallerThanDestinationId &&
              <Alert severity='warning'>You are about to merge an older ID into a newer ID. This is not recommended as Geneva will likely already have the
                older ID in their system. If you would like to continue to merge these accounts, click the merge anyways button. If you would like to change the ID, click the cancel button and switch the IDs.
              </Alert>
            }
            {destinationStudent && sourceStudent &&
              <div style={styles.confirmTable}>
                <TextField
                  label='ID'
                  value={destinationStudent.id}
                  margin='dense'
                  disabled
                  variant='outlined'
                />
                <TextField
                  label='Name'
                  value={_keepSourceName
                    ? _getFormattedName(sourceStudent)
                    : _getFormattedName(destinationStudent)
                  }
                  margin='dense'
                  disabled
                  variant='outlined'
                />

                <TextField
                  label='Email'
                  value={_keepSourceEmail
                    ? sourceStudent.email
                    : destinationStudent.email
                  }
                  margin='dense'
                  disabled
                  variant='outlined'
                />

                <TextField
                  label='Phone number'
                  value={_keepSourcePhone
                    ? getFormattedPhone(sourceStudent.phone)
                    : getFormattedPhone(destinationStudent.phone)
                  }
                  margin='dense'
                  disabled
                  variant='outlined'
                />

                <TextField
                  label='Birthday'
                  value={_keepSourceBirthday
                    ? _getFormattedBirthday(sourceStudent)
                    : _getFormattedBirthday(destinationStudent)
                  }
                  margin='dense'
                  disabled
                  variant='outlined'
                />

                <TextField
                  label='Address'
                  value={_keepSourceAddress
                    ? _getFormattedAddress(sourceStudent)
                    : _getFormattedAddress(destinationStudent)
                  }
                  multiline
                  margin='dense'
                  disabled
                  variant='outlined'
                />
              </div>
            }
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setConfirmStudentMergeDialogIsOpen(!_confirmStudentMergeDialogIsOpen)} color='secondary'>
              Cancel
            </Button>
            <Button
              onClick={() => {
                const sourceFieldsToKeep = ['name', 'email', 'address', 'birthday', 'phone'];
                Object.entries(_selectedFields).forEach(([key, value]) => {
                  if (!value) {
                    sourceFieldsToKeep.pop(key);
                  }
                });
                dispatch(mergeStudents(_sourceStudentId, _destinationStudentId, sourceFieldsToKeep));
                setConfirmStudentMergeDialogIsOpen(!_confirmStudentMergeDialogIsOpen);
              }}
              color='secondary'
              variant='contained'
            >
              {sourceIdSmallerThanDestinationId ? 'Merge anyways' : 'Merge'}
            </Button>
          </DialogActions>
        </Dialog>
      </Paper>
    </div>
  );
};

export default (MergeStudents);

/*
 * Ryan O'Dowd
 * 2019-01-09
 * © Copyright 2024 NursingABC, Inc.  All Rights Reserved.
 */
import Globals from '../../Globals';

const styles = {
  container: {
    width: '100%',
  },
  rangeSelection: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 16,
  },
  panel: {
    width: '100%',
  },
  panelDetails: {
    flexDirection: 'column',
  },
  graphTitle: {
    color: Globals.colors.accent,
  },
  button: {
    margin: 16,
  },
  editTargetsError: {
    color: Globals.colors.error,
  },
};

export default styles;

/*
 * Ryan O'Dowd
 * 2021-02-03
 * © Copyright 2021 NursingABC, Inc.  All Rights Reserved.
 */
import Globals from '../../Globals';

const styles = {
  container: {
    width: '100%',
    textAlign: 'left',
  },
  sectionWrapper: { // @TODO: abstract with student management
    display: 'flex',
    flexDirection: 'column',
  },
  sectionTitle: { // @TODO:abstract with student management
    color: Globals.colors.accent,
  },
  sectionDescription: { // @TODO: abstract with student management
    color: Globals.colors.primary,
    fontSize: 12,
  },
  tableWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  tableToolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  leftItems: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  rightItems: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  inviteButton: {
    marginRight: 5,
  },
  notifyButton: {
    marginLeft: 12,
  },
  transcriptButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '10px',
  },
  newPlaceButton: {
    display: 'flex',
    justifyContent: 'right',
    marginRight: 5,
  },
  lastNotify: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  statusWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: 4,
    height: '100%',
    color: Globals.colors.lightGray,
  },
  statusWrapperTranscript: {
    position: 'absolute',
    top: 0,
    left: 4,
    width: 4,
    height: '100%',
    color: Globals.colors.lightGray,
  },
  errors: {
    color: Globals.colors.error,
    marginLeft: 25,
  },
  formCreation: {
    display: 'grid',
    gridTemplateColumns: '200px 200px',
    gap: '10px',
    marginBottom: '10px',
  },
};

export default styles;

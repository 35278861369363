/*
 * Ryan O'Dowd
 * 2019-03-10
 * © Copyright 2018 NursingABC, Inc.  All Rights Reserved.
 */

const styles = {
  container: {
    display: 'flex',
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 80,
  },
  noTopMargin: {
    marginTop: 0,
  },
};

export default styles;

import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import PropTypes from 'prop-types';
import {
  bindActionCreators,
} from 'redux';
import {
  connect,
} from 'react-redux';
import {
  insertDatabaseRecord,
} from '../../../actions';
import styles from '../styles';
import {
  useState,
} from 'react';

const AddNewPlaceDialog = (props) => {
  const [_title, setTitle] = useState('');
  const [_contactName, setContactName] = useState('');
  const [_email, setEmail] = useState('');
  const [_address, setAddress] = useState('');
  const [_city, setCity] = useState('');
  const [_state, setState] = useState('');
  const [_zip, setZip] = useState('');
  const [_address2, setAddress2] = useState('');
  const [_url, setUrl] = useState('');
  const [_formErrors, setFormErrors] = useState(false);

  function _onClose() {
    setTitle('');
    setContactName('');
    setEmail('');
    setAddress('');
    setCity('');
    setState('');
    setZip('');
    setAddress2('');
    setUrl('');
    setFormErrors(false);
    props.onClose();
  }

  return (
    <Dialog
      open={props.open}
      onClose={_onClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>Add New Place</DialogTitle>
      {_formErrors && <span style={styles.errors}>Please fill out all required fields and try again</span>}
      <DialogContent>
        <p>Please enter the information for the new place</p>
        <div style={styles.formCreation}>
          <TextField
            label='Title'
            value={_title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
          <TextField
            label='Email'
            value={_email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <TextField
            label='Contact name'
            variant='outlined'
            value={_contactName}
            onChange={(e) => setContactName(e.target.value)}
            required
          />
          <TextField
            label='Address'
            value={_address}
            onChange={(e) => setAddress(e.target.value)}
            required
          />
          <TextField
            label='City'
            value={_city}
            onChange={(e) => setCity(e.target.value)}
            required
          />
          <TextField
            label='State'
            value={_state.toUpperCase()}
            onChange={(e) => setState(e.target.value)}
            required
            inputProps={{maxLength: 2}}
          />
          <TextField
            label='Zip'
            value={_zip}
            onChange={(e) => setZip(e.target.value)}
            required
            inputProps={{maxLength: 10}}
          />
          <TextField
            label='Address 2'
            value={_address2}
            onChange={(e) => setAddress2(e.target.value)}
          />
          <TextField
            label='url'
            value={_url}
            onChange={(e) => setUrl(e.target.value)}
          />
        </div>
        <Button
          variant='contained'
          color='secondary'
          onClick ={() => {
            if (!_title || !_email || !_contactName || !_address || !_city || !_state || !_zip) {
              setFormErrors(true);
              return;
            }
            props.insertDatabaseRecord('places', _title + '~' + _email + '~' + _contactName + '~' + _address + '~' + _city + '~' + _state + '~' + _zip + '~' + _address2 + '~' + _url);
            _onClose();
          }}
        >
          Commit to Database
        </Button>
        <Button
          color='secondary'
          onClick={_onClose}
        >
          Cancel
        </Button>
      </DialogContent>
    </Dialog>
  );
};

AddNewPlaceDialog.propTypes = {
  insertDatabaseRecord: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    insertDatabaseRecord,
  }, dispatch);
};

export default connect(() => {}, mapDispatchToProps)(AddNewPlaceDialog);

/*
 * Ally Zernick
 * 2023-10-20
 * © Copyright 2023 NursingABC, Inc.  All Rights Reserved.
 */
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from '@mui/material';
import React, {
  useEffect,
  useState,
} from 'react';
import {
  createInstitutionCampusesLabels,
  fetchInstitutionCampusTypes,
  fetchInstitutionCampusesLabels,
  fetchLabels,
  updateInstitutionCampus,
  updateInstitutionCampusesLabels,
} from '../../../actions';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import Autocomplete from '@mui/material/Autocomplete';
import EditIcon from '@mui/icons-material/Edit';
import PropTypes from 'prop-types';
import provinces from 'provinces';
import styles from '../styles';

const InstitutionCampuses = (props) => {
  const [_edit, setEdit] = useState(false);
  const [_editRow, setEditRow] = useState();
  const [_rowsPerPage, setRowsPerPage] = useState(5);
  const [_pageNum, setPageNum] = useState(0);
  const [_searchTerms, setSearchTerms] = useState('');
  const [_updateFields, setUpdateFields] = useState([]);
  const [_labelUpdate, setLabelUpdate] = useState('');
  const institutionCampusTypes = useSelector((state) => state.institutionCampusTypes);
  const labels = useSelector((state) => state.labels);
  const institutionCampusesLabels = useSelector((state) => state.institutionCampusesLabels);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchInstitutionCampusTypes());
    dispatch(fetchLabels());
    dispatch(fetchInstitutionCampusesLabels());
  }, []);

  const _handleInputChange = (key, value, originalValue) => {
    if (value === originalValue) {
      setUpdateFields(_updateFields.filter((field) => field[0][0] !== key));
    } else if (_updateFields.find((field) => field[0] === key)) {
      setUpdateFields(_updateFields.map((field) => {
        if (field[0] === key) {
          field[key] = value;
          return field;
        } else {
          return field;
        }
      }));
    } else {
      setUpdateFields([..._updateFields, [[key], value]]);
    }
  };

  const searchTerms = _searchTerms.split('|').filter((multipleSearchTerms) => multipleSearchTerms).map((searchTermPart) => searchTermPart.split(' ').map((text) => text.toLowerCase()));
  const filteredData = props.institutionCampuses.filter((institutionCampus) => {
    if (!_searchTerms) {
      return true;
    }

    return searchTerms.some((groupOfSearchTerms) => {
      return groupOfSearchTerms.every((searchTerm) => {
        return Object.keys(institutionCampus).some((institutionCampusAttribute) => {
          return `${institutionCampus[institutionCampusAttribute]}`.toLowerCase().includes(searchTerm);
        });
      });
    });
  });

  if (!labels || !institutionCampusesLabels) {
    return <CircularProgress />;
  }

  return (
    <div>
      <Paper>
        <div style={styles.tableTitle}>
          <h2>Institution Campuses</h2>
          <TextField
            style={styles.search}
            label='Search'
            variant='outlined'
            onChange={(e) => {
              setSearchTerms(e.target.value);
              setPageNum(0);
            }}
          />
        </div>
        <Table
          id='institutionCampuses'
          aria-labelledby='tableTitle'
          size='medium'
        >
          <TableHead>
            <TableRow>
              <TableCell align='left'>Institution Name</TableCell>
              <TableCell align='left'>Campus Name</TableCell>
              <TableCell align='left'>URL</TableCell>
              <TableCell align='left'>City</TableCell>
              <TableCell align='left'>State</TableCell>
              <TableCell align='left'>International</TableCell>
              <TableCell align='left'>Type</TableCell>
              <TableCell align='left'>Labels</TableCell>
              <TableCell align='left'>Last Updated</TableCell>
              <TableCell align='left'>Last Updated By</TableCell>
              <TableCell align='left' />
            </TableRow>
          </TableHead>
          <TableBody>
            {institutionCampusTypes.length > 0 && filteredData.slice(_pageNum * _rowsPerPage, _pageNum * _rowsPerPage + _rowsPerPage).map((institutionCampus) => {
              const name = institutionCampusTypes.find((t) => t.id === institutionCampus.institution_campus_type_id) !== undefined ? institutionCampusTypes.find((t) => t.id === institutionCampus.institution_campus_type_id).name : 'None';
              const institutionCampusLabel = institutionCampusesLabels.length > 0 ? institutionCampusesLabels.find((icl) => icl.institution_campuses_id === institutionCampus.id) : '';
              const label = institutionCampusLabel ? labels.find((l) => l.id === institutionCampusLabel.institution_campuses_label_id) || '' : '';
              let type = '';
              if (label) {
                type = institutionCampusesLabels.find((icl) => icl.institution_campuses_id === institutionCampus.id);
              }
              return (
                <React.Fragment key={institutionCampus.id}>
                  <TableRow
                    hover
                  >
                    <TableCell align='left'>{props.institutions.find((i) => i.id === institutionCampus.institution_id).name}</TableCell>
                    <TableCell align='left'>{institutionCampus.name}</TableCell>
                    <TableCell align='left'>{institutionCampus.url}</TableCell>
                    <TableCell align='left'>{institutionCampus.city}</TableCell>
                    <TableCell align='left'>{institutionCampus.state}</TableCell>
                    <TableCell align='left'>{institutionCampus.international === 0 ? 'NO' : 'YES'}</TableCell>
                    <TableCell align='left'>{name}</TableCell>
                    <TableCell align='left'>{label.label_name ? label.label_name : 'None'}</TableCell>
                    <TableCell align='left'>{institutionCampus.updated_at}</TableCell>
                    <TableCell align='left'>{institutionCampus.updated_by === null ? 'N/A' : institutionCampus.updated_by}</TableCell>
                    <TableCell align='left'>
                      <div style={styles.iconCell}>
                        <IconButton
                          onClick={() => {
                            setEdit(!_edit);
                            setEditRow(institutionCampus.id);
                          }}
                          size='large'
                        >
                          <EditIcon />
                        </IconButton>
                      </div>
                    </TableCell>
                  </TableRow>
                  <Dialog
                    open={_edit && (institutionCampus.id === _editRow)}
                    onClose={() => setEdit(!_edit)}
                    fullWidth={true}
                  >
                    <DialogTitle>Update Institution Campus</DialogTitle>
                    <DialogContent style={styles.dialogContent}>
                      {props.institutions.length > 0 &&
                        <Autocomplete
                          style={styles.dialogEditCell}
                          id='combo-box-demo'
                          options={props.institutions.map((a) => ({
                            id: a.id,
                            name: a.name,
                          }))}
                          defaultValue={props.institutions.find((i) => i.id === institutionCampus.institution_id)}
                          getOptionLabel={(institution) => institution.name}
                          onChange={(e, institution) => _handleInputChange('institution_id', institution?.id, props.institutions.find((i) => i.id === institutionCampus.institution_id).id)}
                          renderInput={(params) =>
                            <TextField
                              {...params}
                              label='*Institution'
                              variant='outlined'
                            />}
                        />
                      }
                      <TextField
                        style={styles.dialogEditCell}
                        variant='outlined'
                        defaultValue={institutionCampus.name}
                        onChange={(e) => _handleInputChange('name', e.target.value, institutionCampus.name)}
                        label='Campus Name'
                      />
                      <TextField
                        style={styles.dialogEditCell}
                        variant='outlined'
                        defaultValue={institutionCampus.url}
                        onChange={(e) => _handleInputChange('url', e.target.value, institutionCampus.url)}
                        label='Campus URL'
                      />
                      <TextField
                        style={styles.dialogEditCell}
                        variant='outlined'
                        defaultValue={institutionCampus.city}
                        onChange={(e) => _handleInputChange('city', e.target.value, institutionCampus.city)}
                        label='Campus City'
                      />
                      <FormControl variant='standard' style={styles.dialogEditCell}>
                        <FormLabel style={styles.stateLabel}>Campus State</FormLabel>
                        <Select
                          onChange={(e) => _handleInputChange('state', e.target.value, institutionCampus.state)}
                          variant='outlined'
                          defaultValue={institutionCampus.state}
                        >
                          {provinces.filter((value) => value.country === 'US' || value.country === 'CA').map((state) => {
                            return <MenuItem key={state.short} value={state.short}>{state.name}</MenuItem>;
                          })}
                        </Select>

                      </FormControl>
                      <FormControl variant='standard' style={styles.dialogEditCell}>
                        <FormLabel style={styles.stateLabel}>Campus Type</FormLabel>
                        <Select
                          onChange={(e) => _handleInputChange('institution_campus_type_id', e.target.value, institutionCampus.institution_campus_type_id !== null ? institutionCampus.institution_campus_type_id : '')}
                          variant='outlined'
                          defaultValue={institutionCampus.institution_campus_type_id ? institutionCampusTypes.find((ict) => ict.id === institutionCampus.institution_campus_type_id).id : ''}
                        >
                          <MenuItem value=''>None</MenuItem>
                          {institutionCampusTypes.map((type) => {
                            return <MenuItem key={type.id} value={type.id}>{type.name}</MenuItem>;
                          })}
                        </Select>
                      </FormControl>
                      <FormControl variant='standard' style={styles.dialogEditCell}>
                        <FormLabel style={styles.stateLabel}>Campus Label</FormLabel>
                        <Select
                          onChange={(e) => setLabelUpdate(e.target.value)}
                          variant='outlined'
                          defaultValue={label ? label.id : ''}
                        >
                          {labels.length > 0 && labels.map((label) => {
                            return <MenuItem key={label.id} value={label.id}>{label.label_name}</MenuItem>;
                          })}
                        </Select>
                      </FormControl>
                      <FormControl style={styles.dialogSwitches}>
                        <FormControlLabel
                          style={styles.dialogSwitch}
                          onChange={(e) => _handleInputChange('international', e.target.checked ? 1 : 0, institutionCampus.international)}
                          control={
                            <Switch
                              defaultChecked={institutionCampus.international}
                            />
                          }
                          label='International'
                        />
                        <FormControlLabel
                          style={styles.dialogSwitch}
                          onChange={(e) => _handleInputChange('disabled', e.target.checked ? 1 : 0, institutionCampus.disabled)}
                          control={
                            <Switch
                              defaultChecked={institutionCampus.disabled}
                            />
                          }
                          label='Disabled'
                        />
                      </FormControl>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        variant='contained'
                        color='primary'
                        onClick={() => {
                          dispatch(updateInstitutionCampus(institutionCampus.id, Object.fromEntries(_updateFields), () => {
                            dispatch(fetchInstitutionCampusTypes());
                            dispatch(fetchLabels());
                          }));
                          if (_labelUpdate && type === '') {
                            dispatch(createInstitutionCampusesLabels({
                              institution_campuses_id: institutionCampus.id,
                              institution_campuses_label_id: _labelUpdate,
                            }));
                          } else if (_labelUpdate && type !== '') {
                            dispatch(updateInstitutionCampusesLabels(type.id, {institution_campuses_label_id: _labelUpdate}));
                          }
                          setUpdateFields([]);
                          setEdit(!_edit);
                        }}
                      >
                        Submit Changes
                      </Button>
                      <Button
                        variant='contained'
                        onClick={() => setEdit(!_edit)}
                      >
                        Cancel
                      </Button>
                    </DialogActions>
                  </Dialog>
                </React.Fragment>
              );
            })}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50]}
          component='div'
          count={filteredData.length}
          rowsPerPage={_rowsPerPage}
          page={_pageNum}
          previousButton={{'aria-label': 'Previous Page'}}
          nextButton={{'aria-label': 'Next Page'}}
          onPageChange={(event, _pageNum) => setPageNum(_pageNum)}
          onRowsPerPageChange={(e) => setRowsPerPage(e.target.value)}
        />
      </Paper>
    </div>
  );
};

InstitutionCampuses.propTypes = {
  institutionCampuses: PropTypes.array,
  institutions: PropTypes.array,
};

export default (InstitutionCampuses);

/*
 * Ally Zernick
 * 2023-10-20
 * © Copyright 2023 NursingABC, Inc.  All Rights Reserved.
 */
import {
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from '@mui/material';
import React, {
  useState,
} from 'react';
import DoneIcon from '@mui/icons-material/Done';
import EditIcon from '@mui/icons-material/Edit';
import PropTypes from 'prop-types';
import styles from '../styles';
import {
  updateInstitutions,
} from '../../../actions';
import {
  useDispatch,
} from 'react-redux';

const Institutions = (props) => {
  const [_edit, setEdit] = useState(false);
  const [_editRow, setEditRow] = useState();
  const [_rowsPerPage, setRowsPerPage] = useState(5);
  const [_pageNum, setPageNum] = useState(0);
  const [_searchTerms, setSearchTerms] = useState('');
  const [_institutionName, setInstitutionName] = useState([]);
  const dispatch = useDispatch();

  const searchTerms = _searchTerms.split('|').filter((multipleSearchTerms) => multipleSearchTerms).map((searchTermPart) => searchTermPart.split(' ').map((text) => text.toLowerCase()));
  const filteredData = props.institutions.filter((institution) => {
    if (!_searchTerms) {
      return true;
    }

    return searchTerms.some((groupOfSearchTerms) => {
      return groupOfSearchTerms.every((searchTerm) => {
        return Object.keys(institution).some((institutionAttribute) => {
          return `${institution[institutionAttribute]}`.toLowerCase().includes(searchTerm);
        });
      });
    });
  });

  return (
    <div>
      <Paper>
        <div style={styles.tableTitle}>
          <h2>Institutions</h2>
          <TextField
            style={styles.search}
            label='Search'
            variant='outlined'
            onChange={(e) => {
              setSearchTerms(e.target.value);
              setPageNum(0);
            }}
          />
        </div>
        <Table
          id='institutions'
          aria-labelledby='tableTitle'
          size='medium'
        >
          <TableHead>
            <TableRow>
              <TableCell align='left'>Institution Name</TableCell>
              <TableCell align='left'>Last Updated</TableCell>
              <TableCell align='left'>Last Updated By</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData.slice(_pageNum * _rowsPerPage, _pageNum * _rowsPerPage + _rowsPerPage).map((institution) => {
              return (
                <React.Fragment key={institution.id}>
                  <TableRow
                    hover
                  >
                    {_edit && _editRow === institution.id ? (
                      <>
                        <TableCell>
                          <TextField
                            error
                            label='Institution Name'
                            style={styles.institutionName}
                            defaultValue={institution.name}
                            onChange={(e) => setInstitutionName(e.target.value)}
                          />
                        </TableCell>
                        <TableCell align='left'>{institution.updated_at}</TableCell>
                        <TableCell align='left'>{institution.updated_by !== null ? institution.updated_by : 'N/A'}</TableCell>
                        <TableCell align='left'>
                          <div style={styles.iconCell}>
                            <IconButton
                              onClick={() => {
                                setEdit(!_edit);
                                setEditRow(institution.id);
                                dispatch(updateInstitutions(institution.id, {name: _institutionName}));
                              }}
                              size='large'
                            >
                              <DoneIcon />
                            </IconButton>
                            <Button
                              color='primary'
                              variant='contained'
                              onClick={() => {
                                setEdit(!_edit);
                                setEditRow(institution.id);
                              }}
                            >
                              Cancel
                            </Button>
                          </div>
                        </TableCell>
                      </>
                    ) : (
                      <>
                        <TableCell align='left'>{institution.name}</TableCell>
                        <TableCell align='left'>{institution.updated_at}</TableCell>
                        <TableCell align='left'>{institution.updated_by !== null ? institution.updated_by : 'N/A'}</TableCell>
                        <TableCell align='left'>
                          <div style={styles.iconCell}>
                            <IconButton
                              onClick={() => {
                                setEdit(!_edit);
                                setEditRow(institution.id);
                              }}
                              size='large'
                            >
                              <EditIcon />
                            </IconButton>
                          </div>
                        </TableCell>
                      </>
                    )}
                  </TableRow>
                </React.Fragment>
              );
            })}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50]}
          component='div'
          count={filteredData.length}
          rowsPerPage={_rowsPerPage}
          page={_pageNum}
          previousButton={{'aria-label': 'Previous Page'}}
          nextButton={{'aria-label': 'Next Page'}}
          onPageChange={(event, _pageNum) => setPageNum(_pageNum)}
          onRowsPerPageChange={(e) => setRowsPerPage(e.target.value)}
        />
      </Paper>
    </div>
  );
};

Institutions.propTypes = {
  institutions: PropTypes.array,
};

export default (Institutions);

/*
 * Ryan O'Dowd
 * 2019-03-10
 * © Copyright 2018 NursingABC, Inc.  All Rights Reserved.
 */
import {
  Link,
} from 'react-router-dom';
import styles from './styles';

const Error404 = () => {
  return (
    <div style={styles.container}>
      <h1>404 Page Not Found</h1>
      <p style={styles.noTopMargin}>Try another link or visit the <Link to='/'>home page</Link>.</p>
    </div>
  );
};

export default Error404;

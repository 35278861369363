/*
 * Logan Baer
 * 2021-10-20
 * © Copyright 2024 NursingABC, Inc.  All Rights Reserved.
 */
import {
  Button,
  TextField,
} from '@mui/material';
import React from 'react';
import {
  exportOutcomeResults,
} from '../../actions';
import {
  useDispatch,
} from 'react-redux';

const ExportOutcomes = () => {
  const [courseId, setCourseId] = React.useState('');
  const dispatch = useDispatch();

  return (
    <div>
      <h3>Export Outcomes</h3>
      <p>Download speeds may be slow, please wait until your file has been successfully downloaded.</p>
      <TextField
        label= 'Canvas Course Id'
        value={courseId}
        onChange={(e) => setCourseId(e.target.value)}
        margin='dense'
      />
      <div>
        <Button
          disabled={!courseId}
          variant='contained'
          color='secondary'
          onClick={() => {
            dispatch(exportOutcomeResults(courseId));
            setCourseId('');
          }}
        >
          Export Outcomes
        </Button>
      </div>
    </div>
  );
};

export default ExportOutcomes;

/*
 * Ally Zernick
 * 2023-10-20
 * © Copyright 2023 NursingABC, Inc.  All Rights Reserved.
 */
import {
  Button,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import {
  DatePicker,
  LocalizationProvider,
} from '@mui/x-date-pickers';
import {
  createTerm,
  fetchPartnerAgreements,
  getTerms,
  patchTerm,
} from '../../actions';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  useEffect,
  useState,
} from 'react';
import {
  AdapterDayjs,
} from '@mui/x-date-pickers/AdapterDayjs';
import Autocomplete from '@mui/material/Autocomplete';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import EditIcon from '@mui/icons-material/Edit';
import Globals from '../../Globals';
import dayjs from 'dayjs';
import styles from './styles';

const Terms = () => {
  const [_termName, setTermName] = useState('');
  const [_startDate, setStartDate] = useState(null);
  const [_endDate, setEndDate] = useState(null);
  const [_disabled, setDisabled] = useState(false);
  const [_selectedForEdit, setSelectedForEdit] = useState();
  const [_selectedPartnerAgreement, setSelectedPartnerAgreement] = useState();
  const [_edit, setEdit] = useState(false);
  const [_searchTerm, setSearchTerm] = useState('');
  const [_showDisabled, setShowDisabled] = useState(false);
  const terms = useSelector((state) => state.terms);
  const partnerAgreements = useSelector((state) => state.partnerAgreements);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTerms());
    dispatch(fetchPartnerAgreements());
  }, [dispatch]);

  const _resetVars = () => {
    setTermName('');
    setStartDate(null);
    setEndDate(null);
    setSelectedPartnerAgreement(null);
    setDisabled(false);
  };

  return (Object.values(terms).length > 0 && Object.values(partnerAgreements).length > 0 &&
    <div>
      <h2>Create a New Term</h2>
      <div style={styles.termContainer}>
        <TextField
          label='*Term Name'
          variant='outlined'
          value={_edit ? null : _termName}
          onChange={(e) => setTermName(e.target.value)}
        />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label='Start Date (optional)'
            value={_edit ? null : _startDate}
            format='MM/DD/YYYY'
            onChange={(e) => setStartDate(e)}
            slotProps={{
              actionBar: {
                actions: ['clear'],
              },
            }}
          />
          <DatePicker
            label='End Date (optional)'
            value={_edit ? null : _endDate}
            format='MM/DD/YYYY'
            onChange={(e) => setEndDate(e)}
            slotProps={{
              actionBar: {
                actions: ['clear'],
              },
            }}
          />
        </LocalizationProvider>
        <FormControl style={styles.quarterWidth} variant='outlined'>
          {partnerAgreements.length > 0 &&
            <Autocomplete
              id='combo-box-demo'
              options={partnerAgreements.map((a) => ({
                institution_campus_id: a.institution_campus_id,
                partner_agreement_id: a.partner_agreement_id,
                school_name: a.school_name,
              }))}
              getOptionLabel={(partner) => partner.school_name}
              onChange={(e, campus) => setSelectedPartnerAgreement(campus)}
              renderInput={(params) =>
                <TextField
                  {...params}
                  label='Partner Agreement School (optional)'
                  variant='outlined'
                />
              }
            />}
        </FormControl>
        <Button
          color='primary'
          variant='contained'
          disabled={_termName === null || _termName === '' || _edit || !!terms.find((t) => _termName === t.term_name)}
          onClick={() => {
            dispatch(createTerm({
              term_name: _termName,
              start_date: _startDate ? dayjs(_startDate).utc().format('YYYY-MM-DD') : null,
              end_date: _endDate ? dayjs(_endDate).utc().format('YYYY-MM-DD') : null,
              institution_campus_partner_agreement_id: _selectedPartnerAgreement ? _selectedPartnerAgreement.partner_agreement_id : null,
              disabled: 0,
            }));
            _resetVars();
          }}
        >
          Create New Term
        </Button>
      </div>
      {!!terms.find((t) => _termName === t.term_name) && !_edit && <p style={{color: Globals.colors.error}}>Term name already exists.</p>}
      <hr width='100%' style={styles.lineSeparator} />
      <Paper style={styles.terms}>
        <div style={styles.flexBox}>
          <div style={styles.fullWidth}>
            <InputLabel>Search</InputLabel>
            <TextField
              variant='outlined'
              placeholder='Term name'
              onChange={(e) => setSearchTerm(e.target.value.toLowerCase())}
            />
          </div>
          <FormControlLabel
            label='Show disabled'
            labelPlacement='start'
            control={
              <Switch
                checked={_showDisabled}
                onChange={() => setShowDisabled(!_showDisabled)}
              />
            }
          />
        </div>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Term Name</TableCell>
              <TableCell>School</TableCell>
              <TableCell>Start Date</TableCell>
              <TableCell>End Date</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {terms.length > 0 && terms.filter((term) => term.term_name.toLowerCase().includes(_searchTerm) && (_showDisabled === true ? term.disabled === 1 || term.disabled === 0 : term.disabled === 0)).map((term) => {
              const institution = partnerAgreements.find((e) => e.partner_agreement_id === term.institution_campus_partner_agreement_id);
              return (
                <TableRow key={term.id}>
                  {_edit && term.id === _selectedForEdit ? (
                    <>
                      <TableCell>
                        <TextField
                          variant='outlined'
                          label='Term name'
                          defaultValue={term.term_name ? term.term_name : ''}
                          value={_termName}
                          onChange={(e) => setTermName(e.target.value)}
                        />
                      </TableCell>
                      <TableCell>
                        <FormControl style={styles.fullWidth} variant='outlined'>
                          {partnerAgreements.length > 0 &&
                            <Autocomplete
                              id='combo-box-demo'
                              options={partnerAgreements.map((a) => ({
                                institution_campus_id: a.institution_campus_id,
                                institution_campus_partner_agreement_id: a.partner_agreement_id,
                                school_name: a.school_name,
                              }))}
                              defaultValue={{label: _selectedPartnerAgreement ? _selectedPartnerAgreement.school_name : (term.school_name ? term.school_name : '')}}
                              getOptionLabel={(value) => value.label !== undefined ? value.label : value.school_name}
                              onChange={(e, campus) => setSelectedPartnerAgreement(campus)}
                              renderInput={(params) =>
                                <TextField
                                  {...params}
                                  label='Partner Agreement School'
                                  variant='outlined'
                                />}
                            />
                          }
                        </FormControl>
                      </TableCell>
                      <TableCell>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            format='MM/DD/YYYY'
                            label='Start Date'
                            value={_startDate ? dayjs(_startDate).utc() : null}
                            onChange={(e) => setStartDate(e)}
                            slotProps={{
                              actionBar: {
                                actions: ['clear'],
                              },
                            }}
                          />
                        </LocalizationProvider>
                      </TableCell>
                      <TableCell>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            format='MM/DD/YYYY'
                            label='End Date'
                            value={_endDate ? dayjs(_endDate).utc() : null}
                            onAccept={(e) => setEndDate(e)}
                            slotProps={{
                              actionBar: {
                                actions: ['clear'],
                              },
                            }}
                          />
                        </LocalizationProvider>
                      </TableCell>
                      <TableCell style={styles.flexBox}>
                        <Select
                          variant='outlined'
                          value={_disabled}
                          onChange={(d) => setDisabled(d.target.value)}
                        >
                          <MenuItem value='0'>ENABLED</MenuItem>
                          <MenuItem value='1'>DISABLED</MenuItem>
                        </Select>
                        <IconButton
                          onClick={() => {
                            _resetVars();
                            setEdit(!_edit);
                          }}
                          size='large'
                        >
                          <CloseIcon />
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            dispatch(patchTerm(_selectedForEdit, {
                              term_name: _termName,
                              start_date: _startDate ? dayjs(_startDate).utc().format('YYYY-MM-DD') : '',
                              end_date: _endDate ? dayjs(_endDate).utc().format('YYYY-MM-DD') : '',
                              institution_campus_partner_agreement_id: _selectedPartnerAgreement ? _selectedPartnerAgreement.institution_campus_partner_agreement_id : '',
                              disabled: _disabled,
                            }));
                            _resetVars();
                            setEdit(!_edit);
                          }}
                          size='large'
                        >
                          <DoneIcon />
                        </IconButton>
                      </TableCell>
                    </>
                  ) : (
                    <>
                      <TableCell>{term.term_name}</TableCell>
                      <TableCell>{institution && <p>{institution.school_name}</p>}</TableCell>
                      <TableCell>{term.start_date && <p>{dayjs(term.start_date).utc().format('YYYY/MM/DD')}</p>}</TableCell>
                      <TableCell>{term.end_date && <p>{dayjs(term.end_date).utc().format('YYYY/MM/DD')}</p>}</TableCell>
                      <TableCell style={styles.flexBox}>
                        {term.disabled ? <p style={styles.error}>Disabled</p> : <p style={styles.success}>Enabled</p>}
                        <IconButton
                          style={styles.editButton}
                          onClick={() => {
                            setEdit(!_edit);
                            setTermName(term.term_name);
                            setSelectedForEdit(term.id);
                            setStartDate(term.start_date);
                            setEndDate(term.end_date);
                            setDisabled(term.disabled);
                            setSelectedPartnerAgreement(institution);
                          }}
                          size='large'
                        >
                          <EditIcon />
                        </IconButton>
                      </TableCell>
                    </>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Paper>
    </div>
  );
};

export default (Terms);

/*
 * Ryan O'Dowd
 * 2019-11-21
 * © Copyright 2024 NursingABC, Inc.  All Rights Reserved.
 */
import Globals from '../../Globals';

const styles = {
  container: {
    width: '100%',
    textAlign: 'left',
    color: Globals.colors.light,
  },
  innerContainer: {
    padding: 16,
  },
  mergeWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginBottom: 64,
  },
  studentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: 8,
    width: '40%',
  },
  textFieldWrapper: {
    display: 'flex',
  },
  textField: {
    display: 'flex',
    flexGrow: 1,
    marginRight: 8,
  },
  swapWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  formFieldWrapper: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 8,
    paddingBottom: 8,
  },
  formFieldLabel: {
    color: Globals.colors.accent,
    margin: 0,
    marginBottom: 4,
    fontSize: '0.95rem',
    fontWeight: '200',
  },
  formFieldContent: {
    margin: 0,
    fontSize: '1.1rem',
    display: 'flex',
  },
  selectableTextField: {
    width: '100%',
    cursor: 'pointer !important',
    // @TODO: the rest is currently straight-up css...figure out how to do that with styles
  },
  row: {
    display: 'flex',
    marginRight: -8,
  },
  nonSelectableTextField: {
    display: 'flex',
    flexGrow: 1,
    marginRight: 8,
  },
  confirmTable: {
    display: 'flex',
    flexDirection: 'column',
  },
  detailsWrapper: {
    marginTop: 32,
    borderTop: `${Globals.colors.primary} solid 1px`,
  },
};

export default styles;

/*
 * Nick Lewyn
 * 2021-07-01
 * © Copyright 2021 NursingABC, Inc.  All Rights Reserved.
 */

import Globals from '../../../Globals';

const styles = {
  discountCodeInputField: {
    width: '100%',
    display: 'flex',
    flex: 1,
    flexGrow: 1,
    marginRight: 8,
  },
  categorySelectField: {
    width: 150,
    marginRight: 8,
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  descriptionParagraph: {
    marginTop: 0,
  },
  description: {
    marginBottom: 16,
  },
  errors: {
    color: Globals.colors.error,
  },
  formRow: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 16,
  },
  inputLabel: {
    left: 'initial',
    width: 120,
  },
  selectField: {
    maxWidth: 300,
    marginRight: 8,
  },
  textField: {
    marginRight: 8,
    // @TODO: change placeholder text color
  },
};

export default styles;

/*
 * Ryan O'Dowd
 * 2019-03-28
 * © Copyright 2024 NursingABC, Inc.  All Rights Reserved.
 */
import {
  Button,
  FormControlLabel,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Toolbar,
  Tooltip,
} from '@mui/material';
import React, {
  useEffect,
  useState,
} from 'react';
import {
  fetchAuthorizationCodes,
  fetchCourses,
  updateCode,
} from '../../actions';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import CreateDiscountDialog from './CreateDiscountDialog';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import {
  nabcGlobalObject,
} from '../../Globals';
import styles from './styles';

dayjs.extend(localizedFormat);

const DISCOUNT_CODE_UNIT_TYPE = nabcGlobalObject({
  percent: 'percent',
  dollars: 'dollars',
});

const DiscountCodes = () => {
  const [_pageNum, setPageNum] = useState(0);
  const [_rowsPerPage, setRowsPerPage] = useState(10);
  const [_createCodeDialogOpen, setCreateCodeDialogOpen] = useState(false);
  const [_activeOnly, setActiveOnly] = useState(true);
  const [_searchText, setSearchText] = useState('');
  const courses = useSelector((state) => Object.values(state.courses).filter((course) => !course.disabled).filter((course) => !course.code.includes('-')).reduce((next, course) => {
    return {...next, [course.id]: course};
  }, {}));
  const codes = useSelector((state) => state.authorizationCodes);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAuthorizationCodes());
    dispatch(fetchCourses());
  }, []);

  const renderHeaderCells = () => {
    const headers = ['Date created', 'Code', 'Value', 'Course', 'Notes', 'Enabled'];
    return headers.map((columnName) => {
      return (
        <TableCell key={columnName} align='right'>
          {columnName}
        </TableCell>
      );
    });
  };

  const getCodeValue = (code) => {
    if (code.unit_type === DISCOUNT_CODE_UNIT_TYPE.dollars) {
      return `$${code.value} off`;
    } else if (code.unit_type === DISCOUNT_CODE_UNIT_TYPE.percent) {
      return `${code.value}% off`;
    }

    return 'unknown';
  };

  const searchTerms = _searchText.split(' ').map((text) => text.toLowerCase());
  const filteredCodes = Object.values(codes).filter((code) => {
    if (_activeOnly && code.disabled) {
      return false;
    }
    return Object.values(code).some((studentAttribute) => {
      return searchTerms.every((searchTerm) => `${studentAttribute}`.toLowerCase().includes(searchTerm));
    });
  }).sort((a, b) => b.id - a.id);
  const emptyRows = _rowsPerPage - Math.min(_rowsPerPage, filteredCodes.length - _pageNum * _rowsPerPage);

  const courseOptions = {};
  Object.values(courses).forEach((course) => {
    courseOptions[course.code] = course;
  });

  return (
    <div style={styles.container}>
      <h3>Authorization Codes</h3>
      <p>Each authorization code can be used no more than one time.</p>
      {_createCodeDialogOpen &&
        <CreateDiscountDialog courseOptions={courseOptions} open={_createCodeDialogOpen} closeDialog={() => setCreateCodeDialogOpen(!_createCodeDialogOpen)} />
      }
      <Paper>
        <Toolbar style={styles.tableToolbar}>
          <div style={styles.leftItems}>
            <TextField
              style={styles.textField}
              variant='standard'
              label='Search'
              value={_searchText}
              onChange={(e) => setSearchText(e.target.value)}
              margin='dense'
            />
            <FormControlLabel
              control={
                <Switch
                  checked={_activeOnly}
                  onClick={() => setActiveOnly(!_activeOnly)}
                />
              }
              label='Active only'
            />
          </div>
          <div style={styles.rightItems}>
            <Button
              style={styles.createButton}
              variant='contained'
              color='primary'
              onClick={() => setCreateCodeDialogOpen(!_createCodeDialogOpen)}
            >
              Create New Code
            </Button>
          </div>
        </Toolbar>
        <div style={styles.tableWrapper}>
          <Table style={styles.table}>
            <TableHead>
              <TableRow>
                {renderHeaderCells()}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredCodes.slice(_pageNum * _rowsPerPage, _pageNum * _rowsPerPage + _rowsPerPage).map((code) => {
                return (
                  <React.Fragment key={code.id}>
                    <TableRow
                      hover
                      tabIndex={-1}
                    >
                      <TableCell align='right'>
                        <Tooltip title={dayjs(code.created_at).format('LT')}>
                          <span>{dayjs(code.created_at).format('L')}</span>
                        </Tooltip>
                      </TableCell>
                      <TableCell align='right'>
                        {code.code}
                      </TableCell>
                      <TableCell align='right'>
                        {getCodeValue(code)}
                      </TableCell>
                      <TableCell align='right'>
                        {courses[code.course_id]
                          ? (
                            <Tooltip title={`${courses[code.course_id].code}: ${courses[code.course_id].title}`}>
                              <span>
                                {courses[code.course_id].code}
                              </span>
                            </Tooltip>
                          ) : (
                            <span>any course</span>
                          )
                        }
                      </TableCell>
                      <TableCell align='right'>
                        {code.description}{/* @TODO: change to notes */}
                      </TableCell>
                      <TableCell align='right'>
                        <Switch
                          checked={!code.disabled}
                          onChange={() => dispatch(updateCode(code.id, code.disabled ? 0 : 1))}
                        />
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                );
              })}
              {emptyRows > 0 && (
                <TableRow style={{height: 49 * emptyRows}}>{/* @TODO: magic number */}
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        {/* @TODO: update pagination props */}
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component='div'
          count={filteredCodes.length}
          rowsPerPage={_rowsPerPage}
          page={_pageNum}
          previousButton={{'aria-label': 'Previous Page'}}
          nextButton={{'aria-label': 'Next Page'}}
          onPageChange={(event, pageNum) => setPageNum(pageNum)}
          onRowsPerPageChange={(event) => setRowsPerPage(event)}
        />
      </Paper>
    </div>
  );
};

export default DiscountCodes;

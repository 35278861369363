/*
 * Ryan O'Dowd, Ally Zernick
 * 2018-11-15
 * © Copyright 2024 NursingABC, Inc.  All Rights Reserved.
 */
import '@aws-amplify/ui-react/styles.css';
import './global.css';
import {
  Authenticator,
  Heading,
  Image,
  Text,
  View,
  useAuthenticator,
} from '@aws-amplify/ui-react';
import {
  StyledEngineProvider,
  ThemeProvider,
  createTheme,
} from '@mui/material/styles';
import {
  persistor,
  store,
} from './Store';
import {
  Amplify,
} from 'aws-amplify';
import App from './components/App';
import {
  Button,
} from '@mui/material';
import Globals from './Globals';
import {
  PersistGate,
} from 'redux-persist/integration/react';
import {
  Provider,
} from 'react-redux';
import {
  cognitoUserPoolsTokenProvider,
} from 'aws-amplify/auth/cognito';
import config from './config';
import {
  createRoot,
} from 'react-dom/client';
import {
  defaultStorage,
} from 'aws-amplify/utils';

Amplify.configure({
  Auth: {
    Cognito: {
      mandatorySignIn: true,
      region: config.cognito.REGION,
      userPoolId: config.cognito.USER_POOL_ID,
      userPoolClientId: config.cognito.APP_CLIENT_ID,
    },
  },
});

cognitoUserPoolsTokenProvider.setKeyValueStorage(defaultStorage);

const components = {
  Header() {
    return (
      <View textAlign='center' margin='75px'>
        <Image
          alt='Portage Learning Logo'
          src={`https://assets.portagelearning.com/logos/portage_main_color.svg`}
        />
      </View>
    );
  },

  Footer() {
    return (
      <View textAlign='center' margin={'15px'}>
        <Text>
          Copyright &copy; 2025 Portage Learning. All Rights Reserved.
        </Text>
      </View>
    );
  },

  SignIn: {
    Header() {
      return (
        <Heading
          paddingTop={'20px'}
          level={4}
          textAlign='center'
        >
          Sign In
        </Heading>
      );
    },
    Footer() {
      const {
        toForgotPassword,
      } = useAuthenticator();

      return (
        <View textAlign='center' marginBottom='10px'>
          <Button
            fontWeight='normal'
            onClick={toForgotPassword}
            size='small'
            variation='link'
          >
            Reset Password
          </Button>
        </View>
      );
    },
  },

  ForgotPassword: {
    Header() {
      return (
        <Heading
          paddingTop={'20px'}
          level={4}
          textAlign='center'
        >
          Reset Password
        </Heading>
      );
    },
  },
  ConfirmResetPassword: {
    Header() {
      return (
        <Heading
          paddingTop={'20px'}
          level={4}
          textAlign='center'
        >
          Confirm Reset Password
        </Heading>
      );
    },
  },
};

const formFields = {
  signIn: {
    username: {
      placeholder: 'Email address*',
    },
    password: {
      placeholder: 'Password*',
    },
  },
  forgotPassword: {
    username: {
      placeholder: 'Email address*',
    },
  },
};

const THEME = createTheme({
  palette: {
    mode: 'light',
    primary: Globals.themePrimary,
    secondary: Globals.themeAccent,
  },
  typography: {
    useNextVariants: true,
  },
});

const rootJsx = (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={THEME}>
          <Authenticator
            components={components}
            formFields={formFields}
            hideDefault={true}
            hideSignUp
          >
            <App />
          </Authenticator>
        </ThemeProvider>
      </StyledEngineProvider>
    </PersistGate>
  </Provider>
);

const root = createRoot(document.getElementById('root'));
root.render(rootJsx);

/*
 * Stevie Michalik
 * 2024-05-22
 * © Copyright 2024 NursingABC, Inc.  All Rights Reserved.
 */

const styles = {
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  paper: {
    padding: '10px',
  },
  formControl: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    marginBottom: '10px',
  },
};

export default styles;

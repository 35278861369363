/*
 * Rob DePietro
 * 2024-09-19
 * © Copyright 2024 NursingABC, Inc.  All Rights Reserved.
 */
import {
  IconButton,
  Tooltip,
} from '@mui/material';
import BookIcon from 'mdi-react/BookIcon';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import {
  fetchCourseSyllabus,
} from '../../../../actions';
import {
  useDispatch,
} from 'react-redux';

const CourseSyllabus = (props) => {
  const dispatch = useDispatch();

  return (
    <Tooltip title='Course syllabus at time of registration' aria-label='Course syllabi at time of registration'>
      <IconButton
        aria-controls='course-syllabus'
        aria-haspopup='false'
        color='secondary'
        onClick={() => dispatch(fetchCourseSyllabus(dayjs(props.createdAt).unix(), props.courseCode.replace(/\s/g, '').toLowerCase()))}
      >
        <BookIcon></BookIcon>
      </IconButton>
    </Tooltip>
  );
};

CourseSyllabus.propTypes = {
  createdAt: PropTypes.any,
  courseCode: PropTypes.any,
  code: PropTypes.any,
};

export default CourseSyllabus;

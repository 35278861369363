/*
 * Ryan O'Dowd
 * 2019-03-19
 * © Copyright 2024 NursingABC, Inc.  All Rights Reserved.
 */
import Globals from '../../Globals';

const styles = {
  container: {
    width: '100%',
    textAlign: 'left',
  },
  highlightedText: {
    color: Globals.colors.light,
    backgroundColor: Globals.colors.accent,
  },
  textField: {
    marginRight: 8,
  },
  disabledAdminNameCell: {
    backgroundColor: `${Globals.colors.primary}77`,
  },
  disabledAdminPerms: {
    opacity: '0.3',
  },
  firstColumn: {
    position: 'sticky',
    left: 0,
    zIndex: 1,
  },
  masqueradeAndNameWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  tableWrapper: {
    height: 'calc(100vh - 320px)',
    overflowX: 'auto',
  },
  table: {
    width: '100%',
  },
  tableHeader: {
    position: 'relative',
    zIndex: 3,
    backgroundColor: Globals.colors.light,
  },
  headerCell: {
    position: 'sticky',
    left: 0,
    zIndex: 1,
    height: 150,
    backgroundColor: 'transparent',
  },
  columnHeaderTextWrapper: {
    position: 'relative',
    backgroundColor: Globals.colors.light,
  },
  columnHeaderText: {
    width: 200,
    display: 'block',
    position: 'absolute',
    bottom: -10,
    left: -8,
    transform: 'rotate(320deg)',
    textAlign: 'left',
    backgroundColor: Globals.colors.light,
  },
  permCell: {
    padding: 6,
  },
  permIcon: {
    cursor: 'pointer',
  },
  pointer: {
    cursor: 'pointer',
  },
  controlsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0px 25px',
  },
  spacer: {
    width: 20,
  },
  addRemovePermRow: {
    alignItems: 'baseline',
    display: 'flex',
    marginTop: 10,
  },
  addRemovePerm: {
    minWidth: 200,
  },
};

export default styles;

/*
 * Nick Lewyn
 * 2024-01-12
 * © Copyright 2024 NursingABC, Inc.  All Rights Reserved.
 */

import {
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import React, {
  useEffect,
  useState,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import Globals from '../../../../Globals';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import {
  fetchDeptChairReport,
} from '../../../../actions';

const FacultyDashboardDataView = ({selectedInstructor = null}) => {
  const [_dataSet, setDataSet] = useState(null);
  const [_pageNumber, setPageNumber] = useState(0);
  const [_rowsPerPage, setRowsPerPage] = useState(5);
  const [_emptyRows, setEmptyRows] = useState(0);
  const instructorOpenAssignments = useSelector((state) => state.fetchInstructorReview);
  const loadingStates = useSelector((state) => state.loadingStates);
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedInstructor) {
      dispatch(fetchDeptChairReport(selectedInstructor.id));
    } else {
      setDataSet([]);
    }
  }, [selectedInstructor, dispatch]);

  useEffect(() => {
    setEmptyRows(_rowsPerPage - Math.min(_rowsPerPage, instructorOpenAssignments.length - _pageNumber * _rowsPerPage));
  }, [_rowsPerPage]);

  useEffect(() => {
    setDataSet(instructorOpenAssignments);
  }, [instructorOpenAssignments]);
  return (
    <Paper>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Course</TableCell>
            <TableCell>Student</TableCell>
            <TableCell>Assignment</TableCell>
            <TableCell>Date Submitted</TableCell>
            <TableCell>Number of Days Ungraded</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {selectedInstructor && !_dataSet.length && !loadingStates.fetchDeptChairReport
            ? <p>No records for selected instructor</p>
            : selectedInstructor && _dataSet.length > 0 && _dataSet.sort((i1, i2) => {
              return (i1.date_diff_since_submitted < i2.date_diff_since_submitted) ? 1 : (i1.date_diff_since_submitted > i2.date_diff_since_submitted) ? -1 : 0;
            }).slice(_pageNumber * _rowsPerPage, _pageNumber * _rowsPerPage + _rowsPerPage).map((row, index) => {
              return (
                <React.Fragment key={index}>
                  <TableRow>
                    <TableCell>{row.display_code_desc}</TableCell>
                    <TableCell>{`${row.student_first_name} ${row.student_last_name}`}</TableCell>
                    <TableCell>
                      <Link
                        target='_blank'
                        rel='noopener noreferrer'
                        href={`${Globals.canvasUrl}/courses/${row.canvas_course_id}/grades/${row.canvas_id}`}
                      >{row.assignment_name}</Link>
                    </TableCell>
                    <TableCell>{dayjs(row.submitted_at).local().format('L LT')}</TableCell>
                    <TableCell>{row.date_diff_since_submitted}</TableCell>
                  </TableRow>
                </React.Fragment>
              );
            })
          }
          {_emptyRows > 0 && (
            <TableRow style={{height: 49 * _emptyRows}}>{/* @TODO: magic number */}
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        component='div'
        count={selectedInstructor ? instructorOpenAssignments.length : 0}
        rowsPerPage={_rowsPerPage}
        page={_pageNumber}
        backIconButtonProps={{'aria-label': 'Previous Page'}}
        nextIconButtonProps={{'aria-label': 'Next Page'}}
        onPageChange={(event, pageNum) => setPageNumber(pageNum)}
        onRowsPerPageChange={(event) => setRowsPerPage(event.target.value)}
      />
    </Paper>

  );
};

FacultyDashboardDataView.propTypes = {
  selectedInstructor: PropTypes.object.isRequired,
};

export default FacultyDashboardDataView;

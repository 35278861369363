import Globals from '../../../Globals';

/*
 * Ryan O'Dowd
 * 2021-05-24
 * © Copyright 2021 NursingABC, Inc.  All Rights Reserved.
 */

const styles = {
  formControlWrapper: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '10px',
  },
  formControl: {
    width: '150px',
  },
  canvasSectionContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  divisionLine: {
    marginTop: 50,
    width: '100%',
    height: 3,
    display: 'block',
    backgroundColor: Globals.colors.primary,
  },
  observerContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
  },
  input: {
    border: '1px solid gray',
    borderRadius: '5px',
    padding: '5px',
    height: '45px',
  },
  inputContainer: {
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
  },
  instructorContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
  },
  degreeInputContainer: {
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
  },
  degreeDisplayContainer: {
    display: 'flex',
    padding: '4px',
  },
  buttonContainer: {
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
  },
  metadataContainer: {
    gap: '10px',
    display: 'flex',
    flexDirection: 'column',
  },
  degreeCard: {
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
  },
  degreeList: {
    display: 'flex',
    gap: '10px',
    flexDirection: 'column',
  },
  passwordError: {
    color: '#ff0000',
  },
  toggleGroup: {
    display: 'flex',
    flexDirection: 'column',
  },
};

export default styles;

/*
 * Ryan O'Dowd
 * 2021-02-03
 * © Copyright 2021 NursingABC, Inc.  All Rights Reserved.
 */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Toolbar,
  Tooltip,
} from '@mui/material';
import {
  apiRequestLogError,
  createElectronicTranscriptTransferS3CognitoAccount,
  downloadSecureFile,
  fetchElectronicTranscriptSchools,
  fetchElectronicTranscriptsBySchool,
  fetchSecureFileS3PreAuthLink,
  fileFailedDownloadFromS3Bucket,
  removeTranscriptFromBucket,
  resendElectronicTranscriptTransferInvite,
  saveFileFromDownloadedS3Bucket,
  sendElectronicTranscriptTransferFileNotification,
  setETTSSharedFiles,
  setUploadedS3ElectronicTranscript,
  uploadElectronicTranscript,
} from '../../actions';
import AddNewPlaceDialog from './AddNewPlaceDialog';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from 'mdi-react/DownloadIcon';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Globals from '../../Globals';
import Highlighter from 'react-highlight-words';
import PropTypes from 'prop-types';
import React from 'react';
import ResetUserPassword from '../Shared/ResetPassword';
import {
  bindActionCreators,
} from 'redux';
import {
  connect,
} from 'react-redux';
import dayjs from 'dayjs';
import {
  fileHumanReadableSize,
} from '../../utilities';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import styles from './styles';
import utc from 'dayjs/plugin/utc';
import {
  v4 as uuidv4,
} from 'uuid';

dayjs.extend(utc);
dayjs.extend(localizedFormat);

class ElectronicTranscriptTransfer extends React.Component {
  static propTypes = {
    admin: PropTypes.object.isRequired,
    apiRequestLogError: PropTypes.func,
    downloadSecureFile: PropTypes.func.isRequired,
    electronicTranscripts: PropTypes.object.isRequired,
    electronicTranscriptTransferNotification: PropTypes.object.isRequired,
    fetchElectronicTranscriptsBySchool: PropTypes.func.isRequired,
    fetchElectronicTranscriptSchools: PropTypes.func.isRequired,
    fetchSecureFileS3PreAuthLink: PropTypes.func.isRequired,
    fileFailedDownloadFromS3Bucket: PropTypes.func,
    loadingStates: PropTypes.object.isRequired,
    s3Actions: PropTypes.object.isRequired,
    saveFileFromDownloadedS3Bucket: PropTypes.func.isRequired,
    sendElectronicTranscriptTransferFileNotification: PropTypes.func.isRequired,
    setETTSSharedFiles: PropTypes.func.isRequired,
    setUploadedS3ElectronicTranscript: PropTypes.func.isRequired,
    uploadElectronicTranscript: PropTypes.func.isRequired,
    removeTranscriptFromBucket: PropTypes.func.isRequired,
    resendElectronicTranscriptTransferInvite: PropTypes.func.isRequired,
    createElectronicTranscriptTransferS3CognitoAccount: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      uploadDialogIsOpen: false,
      newPlaceDialogIsOpen: false,
      uploadEmail: '',
      uploadFilename: '',
      uploadContentType: '',
      filesToUpload: [],
      searchText: '',
      pageNum: 0,
      rowsPerPage: 10,
      fileToUploadSize: 0,
      displayDownloadProgress: false,
      currentDownloadFileId: null,
      showActiveTranscriptsOnly: true,
      sortKey: 'uploaded_timestamp',
      sortAsc: false,
      loadedTranscriptsList: [],
      selectedSchoolEmail: '',
      uploadSchoolId: 0,
    };
  }

  componentDidMount() {
    this.props.fetchElectronicTranscriptSchools();
  }

  componentDidUpdate() {
    if (this.props.s3Actions.s3DownloadPreAuthReceived) {
      fetch(this.props.s3Actions.s3DownloadPreAuthURL, {
        method: 'GET',
      })
        .then((response) => {
          if (!response.ok) {
            this.props.fileFailedDownloadFromS3Bucket();
            this.setState({displayDownloadProgress: false, currentDownloadFileId: null});
            throw new Error(`${response.status} - ${response.statusText}`);
          }
          response.blob().then((blob) => {
            this.props.saveFileFromDownloadedS3Bucket(blob, this.props.s3Actions.filename, blob.type);
            this.setState({displayDownloadProgress: false, currentDownloadFileId: null});
          });
        })
        .catch((error) => {
          this.props.apiRequestLogError('ElectronicTranscriptTransfer', `An error occurred when downloading file from Electronic-Transcripts ${error}`);
        });
    }
  }

  _formatReceivedFileName(fileName) {
    const TIMESTAMP_FILE_NAME = /__[0-9]{10}_[0-9]{10}/;
    return fileName.replace(TIMESTAMP_FILE_NAME, '');
  }

  _cancelUpload() {
    this.setState({
      newPlaceDialogIsOpen: false,
      uploadDialogIsOpen: false,
      uploadEmail: '',
      uploadFilename: '',
      uploadContentType: '',
      fileToUpload: null,
      uploadSchoolId: 0,
    });
  }

  _filesAvailableForDownload(files) {
    return files.map((file) => {
      return file.name.includes('received');
    }).every((value) => value === true);
  }

  _renderPanelWithTable(et) {
    const searchTerms = this.state.searchText.split(' ').map((text) => text.toLowerCase()); // @TODO: search doesn't work (should match only against file name)
    const filteredData = et.transcripts.filter((file) => {
      if (this.state[`${et.email}ShowActiveTranscriptsOnly`] && file.received_timestamp) {
        return false;
      }

      return searchTerms.every((searchTerm) => {
        return Object.keys(file).some((fileAttribute) => {
          return `${file[fileAttribute]}`.toLowerCase().includes(searchTerm);
        });
      });
    });
    const emptyRows = this.state.rowsPerPage - Math.min(this.state.rowsPerPage, filteredData.length - this.state.pageNum * this.state.rowsPerPage);

    let accountState = 'notInvited';
    if (et.invited) {
      accountState = 'pendingAcceptance';
    }
    if (et.invited && et.cognito_account_status === 'CONFIRMED') {
      accountState = 'accountConfirmed';
    }
    return (
      <Accordion
        onChange={(event, expanded) => {
          if (expanded && !this.state.loadedTranscriptsList.includes(et.email)) {
            this.setState({loadedTranscriptsList: [...this.state.loadedTranscriptsList, et.email], selectedSchoolEmail: et.email});
            this.props.fetchElectronicTranscriptsBySchool(et.email);
          }
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          {<span style={{...styles.statusWrapper, backgroundColor: Globals.colors.ettsSchoolAccounts[accountState]}}></span>}
          {et.has_files_waiting_for_download && <span style={{...styles.statusWrapperTranscript, backgroundColor: Globals.colors.ettsSchoolAccounts.pendingTranscripts}}></span>}
          <div style={styles.sectionWrapper}>
            <span style={styles.sectionTitle}>{et.school_name}</span>
            <span style={styles.sectionDescription}>{et.email}</span>
          </div>
          {this.state.selectedSchoolEmail === et.email && this.props.loadingStates.fetchElectronicTranscriptsBySchool && <CircularProgress size={30}/>}
        </AccordionSummary>
        <AccordionDetails>
          <Paper style={styles.tableWrapper}>
            <Toolbar style={styles.tableToolbar}>
              <div style={styles.leftItems}>
                <TextField
                  style={styles.textField}
                  label='Search'
                  value={this.state.searchText}
                  onChange={(e) => this.setState({searchText: e.target.value})}
                  margin='dense'
                />
                <FormControlLabel
                  sx={{marginLeft: '10px'}}
                  control={
                    <Switch
                      checked={!(this.state[`${et.email}ShowActiveTranscriptsOnly`])}
                      onChange={(event) => {
                        this.setState({[`${et.email}ShowActiveTranscriptsOnly`]: !event.target.checked});
                      }}
                    />
                  }
                  label='Show received'
                />
                {(et.invited) &&
                  <ResetUserPassword userId={et.id} schoolName={et.school_name} requestType='etts'/>
                }
              </div>
              <div style={styles.rightItems}>
                {et.invited
                  ? (et.cognito_account_status !== 'FORCE_CHANGE_PASSWORD' ? (
                    <div>
                      <div style={styles.transcriptButtons}>
                        <Button
                          variant='contained'
                          color='primary'
                          onClick={() => this.setState({
                            uploadDialogIsOpen: true,
                            uploadEmail: et.email,
                            uploadSchoolId: et.id,
                          })}
                        >
                          Upload new
                        </Button>
                        <Button
                          style={styles.notifyButton}
                          variant='contained'
                          color='primary'
                          onClick={() => this.props.sendElectronicTranscriptTransferFileNotification(et.email)}
                          disabled={this._filesAvailableForDownload(et.transcripts)}
                        >
                          Notify
                        </Button>
                      </div>
                      <div>
                        <p>Last notified sent: {dayjs(this.props.electronicTranscriptTransferNotification[et.email]).local().format('LLL')}</p>
                      </div>
                    </div>
                  ) : (
                    <Button
                      style={styles.inviteButton}
                      variant='contained'
                      color='primary'
                      onClick={() => this.props.resendElectronicTranscriptTransferInvite(et.email)}
                    >
                      Re-Invite
                    </Button>
                  )) : (
                    <Button
                      style={styles.inviteButton}
                      variant='contained'
                      color='primary'
                      onClick={() => this.props.createElectronicTranscriptTransferS3CognitoAccount(et.email)}
                    >
                      Invite
                    </Button>
                  )
                }
              </div>
            </Toolbar>
            <div>
              <Table aria-labelledby='tableTitle'>
                {/* @TODO: make columns sortable? */}
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <TableSortLabel
                        active={this.state.sortKey === 'name'}
                        direction={this.state.sortAsc ? 'asc' : 'desc'}
                        onClick={() => {
                          const SORT_KEY = 'name';
                          this.setState((prevState) => {
                            return {
                              sortKey: SORT_KEY,
                              sortAsc: prevState.sortKey === SORT_KEY ? !prevState.sortAsc : true,
                            };
                          });
                        }}
                      >
                        File name
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={this.state.sortKey === 'uploaded_timestamp'}
                        direction={this.state.sortAsc ? 'asc' : 'desc'}
                        onClick={() => {
                          const SORT_KEY = 'uploaded_timestamp';
                          this.setState((prevState) => {
                            return {
                              sortKey: SORT_KEY,
                              sortAsc: prevState.sortKey === SORT_KEY ? !prevState.sortAsc : true,
                            };
                          });
                        }}
                      >
                        Date modified
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={this.state.sortKey === 'received_timestamp'}
                        direction={this.state.sortAsc ? 'asc' : 'desc'}
                        onClick={() => {
                          const SORT_KEY = 'received_timestamp';
                          this.setState((prevState) => {
                            return {
                              sortKey: SORT_KEY,
                              sortAsc: prevState.sortKey === SORT_KEY ? !prevState.sortAsc : true,
                            };
                          });
                        }}
                      >
                        Date downloaded
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>Download</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredData.sort((a, b) => {
                    let valueOfA = a[this.state.sortKey];
                    let valueOfB = b[this.state.sortKey];
                    if (!isNaN(Date.parse(valueOfA)) && !isNaN(Date.parse(valueOfB))) {
                      valueOfA = Date.parse(valueOfA);
                      valueOfB = Date.parse(valueOfB);

                      if (this.state.sortAsc) {
                        return valueOfA - valueOfB;
                      }
                      return valueOfB - valueOfA;
                    }
                    if (typeof valueOfA === 'string') {
                      valueOfA = valueOfA.toLowerCase().trim();
                      valueOfB = valueOfB.toLowerCase().trim();

                      if (this.state.sortAsc) {
                        return valueOfA > valueOfB ? 1 : -1;
                      }
                      return valueOfA < valueOfB ? 1 : -1;
                    }
                    return 0;
                  }).slice(this.state.pageNum * this.state.rowsPerPage, this.state.pageNum * this.state.rowsPerPage + this.state.rowsPerPage).map((file) => {
                    return (
                      <React.Fragment key={file.id}>
                        <TableRow
                          hover
                          tabIndex={-1}
                        >
                          <TableCell>
                            {/* @TODO: in dev, 11 matches 23.txt, 8.txt, 9.txt, and desktop.png */}
                            <Highlighter
                              highlightStyle={styles.highlightedText}
                              searchWords={this.state.searchText.split(' ')}
                              autoEscape={true}
                              textToHighlight={this.state[`${et.email}ShowActiveTranscriptsOnly`] ? file.name : this._formatReceivedFileName(file.name)}
                            />
                          </TableCell>
                          <TableCell>
                            {dayjs(file.uploaded_timestamp).format('ddd M/D, LT')}
                          </TableCell>
                          <TableCell>
                            {file.received_timestamp ? dayjs(file.received_timestamp).format('ddd M/D, LT') : '-'}
                          </TableCell>
                          <TableCell>
                            <IconButton
                              color='secondary'
                              onClick={() => {
                                this.props.downloadSecureFile(Globals.s3Buckets.etts, `${et.email}/${file.name}`);
                                this.setState({displayDownloadProgress: true, currentDownloadFileId: file.id});
                              }}
                              size='large'
                            >
                              <DownloadIcon />
                              {this.state.displayDownloadProgress && this.state.currentDownloadFileId === file.id && <CircularProgress size={30}/>}
                            </IconButton>
                            {file.size}
                          </TableCell>
                          <TableCell>
                            {!file.received_timestamp &&
                              <Tooltip title='Delete'>
                                <IconButton
                                  color='secondary'
                                  onClick={() => {
                                    this.props.removeTranscriptFromBucket(`${et.email}/${file.name}`, et.id);
                                  }}
                                  size='large'
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                            }
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{height: 49 * emptyRows}}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </div>
            {/* @TODO: update pagination props */}
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100]}
              component='div'
              count={filteredData.length}
              rowsPerPage={this.state.rowsPerPage}
              page={this.state.pageNum}
              previousButton={{'aria-label': 'Previous Page'}}
              nextButton={{'aria-label': 'Next Page'}}
              onPageChange={(event, pageNum) => this.setState({pageNum})}
              onRowsPerPageChange={(event) => this.setState({rowsPerPage: event.target.value})}
            />
          </Paper>
        </AccordionDetails>
      </Accordion>
    );
  }

  render() {
    return (
      <div style={styles.container}>
        <h3>Electronic Transcript Transfer
          <div style={styles.newPlaceButton}>
            <Button
              variant='contained'
              color='primary'
              onClick={() => {
                this.setState({
                  newPlaceDialogIsOpen: true,
                });
              }}
            >
              Add New Place
            </Button>
          </div>
        </h3>
        {Object.values(this.props.electronicTranscripts).sort((a, b) => a.school_name > b.school_name ? 1 : -1).map((et) => {
          return (
            <React.Fragment key={et.email}>
              {this._renderPanelWithTable(et)}
              <br />
            </React.Fragment>
          );
        })}
        <AddNewPlaceDialog
          open={this.state.newPlaceDialogIsOpen}
          onClose={() => {
            this.setState({newPlaceDialogIsOpen: false});
          }}
        />
        <Dialog
          open={this.state.uploadDialogIsOpen}
          onClose={() => this._cancelUpload}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title'>Upload transcript</DialogTitle>
          <DialogContent>
            <p>Please select the transcript to upload.  (Must be a PDF.)</p>
            <Button
              variant='contained'
              color='secondary'
              component='label'
            >
              Upload
              <input
                type='file'
                color='primary'
                id='upload-button'
                accept='application/pdf'
                multiple='multiple'
                hidden={true}
                onChange={(i) => {
                  const filesToUpload = [...i.target.files].map((file) => {
                    return {
                      uuid: uuidv4(),
                      fileToUpload: file,
                      uploadFilename: file.name,
                      uploadContentType: file.type,
                      fileToUploadSize: file.size,

                    };
                  });
                  this.setState({filesToUpload});
                }}
              />
            </Button>
            <ul>
              {this.state.filesToUpload.map((f, index) => <li key={index}>{f.uploadFilename}</li>)}
            </ul>
          </DialogContent>
          <DialogActions>
            <Button
              variant='contained'
              color='primary'
              disabled={!this.state.filesToUpload.length}
              onClick={() => {
                for (const file of this.state.filesToUpload) {
                  this.props.fetchSecureFileS3PreAuthLink(this.state.uploadEmail, file.uploadFilename, file.uploadContentType, file.uuid, 'etts', () => {
                    this.props.uploadElectronicTranscript(file.fileToUpload, this.props.s3Actions[file.uuid].s3UploadPreAuthURL, file.uploadContentType, () => {
                      const electronicTranscriptInfo = {
                        date_modified: dayjs(),
                        id: uuidv4(),
                        name: file.uploadFilename,
                        size: fileHumanReadableSize(file.fileToUploadSize),
                      };
                      this.props.setETTSSharedFiles(this.state.uploadEmail, file.uploadFilename, this.state.uploadSchoolId);
                      this.props.setUploadedS3ElectronicTranscript(this.state.uploadEmail, electronicTranscriptInfo);
                    });
                  });
                }
                this.setState({uploadDialogIsOpen: false, filesToUpload: []});
              }}
            >
              Upload
            </Button>
            {this.props.loadingStates.uploadElectronicTranscript && <CircularProgress size={30}/>}
            <Button
              color='secondary'
              onClick={() => this._cancelUpload()}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    electronicTranscripts: state.electronicTranscripts,
    s3Actions: state.s3Actions,
    loadingStates: state.loadingStates,
    admin: state.admin,
    electronicTranscriptTransferNotification: state.electronicTranscriptTransferNotification,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    apiRequestLogError,
    createElectronicTranscriptTransferS3CognitoAccount,
    downloadSecureFile,
    fetchSecureFileS3PreAuthLink,
    fetchElectronicTranscriptsBySchool,
    fetchElectronicTranscriptSchools,
    fileFailedDownloadFromS3Bucket,
    removeTranscriptFromBucket,
    resendElectronicTranscriptTransferInvite,
    saveFileFromDownloadedS3Bucket,
    sendElectronicTranscriptTransferFileNotification,
    setETTSSharedFiles,
    setUploadedS3ElectronicTranscript,
    uploadElectronicTranscript,
  }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ElectronicTranscriptTransfer);

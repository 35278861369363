/**
 * Ryan O'Dowd
 * 2018-12-17
 * © Copyright 2018 NursingABC, Inc. All Rights Reserved.
 */
import {
  persistReducer,
  persistStore,
} from 'redux-persist';
import apiAuthorizationMiddleware from './middleware/apiAuthorizationMiddleware';
import {
  apiMiddleware,
} from 'redux-api-middleware';
import {
  configureStore,
} from '@reduxjs/toolkit';
import rootReducer from './reducers';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web and AsyncStorage for react-native
import {
  thunk,
} from 'redux-thunk';

const persistConfig = {
  key: 'adminPanelRoot',
  storage,
  blacklist: [
    's3Actions',
    'students',
    'unassignedStudentIds',
    'grades', // @TODO: restore this once it's stable
    'registrations',
    'canvasCourses',
    'inFlightCalls',
    'knownSchools',
    'unknownSchools',
    'studentCourses', // @TODO: rename to enrollments
    'loadingStates',
    'transcriptRequests',
    'displayStudentProgressData',
    'fetchStudent',
    'displayStudentsWithLastName',
    'partnerStudents',
    'lowGradeWarnings',
    'integrityViolations',
    'assignmentViolationReport',
    'fetchInstructorReview',
  ], // @NOTE: these reducers are too big or shouldn't be included in persistent storage
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: () => [thunk, apiAuthorizationMiddleware, apiMiddleware],
});
export const persistor = persistStore(store);

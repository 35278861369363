/*
 * Ally Zernick
 * 2022-08-08
 * © Copyright 2023 NursingABC, Inc.  All Rights Reserved.
 */
import {
  Button,
  Card,
  CardContent,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import {
  fetchPartnerSchools,
  getAllCampuses,
  patchPartnerSchool,
} from '../../actions';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  useEffect,
  useState,
} from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import CloseIcon from '@mui/icons-material/Close';
import CreateIcon from '@mui/icons-material/Create';
import {
  DEVELOPER,
} from '../../Globals';
import DoneIcon from '@mui/icons-material/Done';
import {
  Link,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import provinces from 'provinces';
import styles from './styles';

const PartnerSchools = () => {
  const [_searchTerm, setSearchTerm] = useState('');
  const [_fileToUpload, setFileToUpload] = useState(null);
  const [_uploadFileName, setUploadFileName] = useState(null);
  const [_schoolState, setSchoolState] = useState('state');
  const [_edit, setEdit] = useState(false);
  const [_editCardId, setEditCardId] = useState(0);
  const [_editSchoolName, setEditSchoolName] = useState(null);
  const [_editSchoolNameLong, setEditSchoolNameLong] = useState(null);
  const [_editSchoolURL, setEditSchoolURL] = useState(null);
  const [_editExtraInformation, setEditExtraInformation] = useState(null);
  const [_editBackgroundColor, setEditBackgroundColor] = useState(null);
  const [_editProgram, setEditProgram] = useState(null);
  const [_editPreReq, setEditPreReq] = useState(null);
  const admin = useSelector((state) => state.admin);
  const partnerSchools = useSelector((state) => state.fetchPartnerSchools);
  const states = provinces.filter((value) => value.country === 'US');
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchPartnerSchools());
    dispatch(getAllCampuses());
  }, [dispatch]);

  const _saveEdits = (id) => {
    dispatch(patchPartnerSchool(id, _editSchoolName, _editSchoolNameLong, _editSchoolURL,
      _editBackgroundColor, _uploadFileName, _fileToUpload, _editExtraInformation, _editProgram, _editPreReq,
      () => {dispatch(fetchPartnerSchools());}));
    _cancelEdits();
  };

  const _cancelEdits = () => {
    setEdit(!_edit);
    setEditSchoolName(null);
    setEditSchoolNameLong(null);
    setEditSchoolURL(null);
    setEditExtraInformation(null);
    setEditBackgroundColor(null);
    setEditProgram(null);
    setEditPreReq(null);
    setUploadFileName(null);
    setFileToUpload(null);
  };

  const _renderPartners = () => {
    const filteredSchools = partnerSchools.filter((schools) => ((schools.school_name_large).toLowerCase().indexOf(_searchTerm.toLowerCase()) !== -1 || (schools.school_name).toLowerCase().indexOf(_searchTerm.toLowerCase()) !== -1) && (schools.state === _schoolState || _schoolState === 'state')).sort((a, b) => a.school_name.localeCompare(b.school_name));
    return (
      <div>
        {!Object.values(filteredSchools).length ? (_schoolState !== 'state' ? (<p>No Schools Found.</p>) : (<p>Loading</p>))
          : (<div style={{display: 'flex', flexWrap: 'wrap'}}>
            {filteredSchools.map((schoolData) => (
              <Card key={schoolData.id} style={styles.container}>
                <CardContent style={styles.cardContent}>
                  {_edit && (schoolData.id === _editCardId) ? (
                    <div>
                      {!!admin.perms[DEVELOPER] &&
                        <div style={styles.editFields}>
                          <TextField
                            label='School Name'
                            style={styles.inputLabel}
                            defaultValue={schoolData.school_name}
                            onChange={(e) => setEditSchoolName(e.target.value)}
                          />
                          <TextField
                            label='School Name Long'
                            style={styles.inputLabel}
                            defaultValue={schoolData.school_name_large}
                            onChange={(e) => setEditSchoolNameLong(e.target.value)}
                          />
                          <TextField
                            label='Special School Information'
                            style={styles.inputLabel}
                            multiline
                            defaultValue={schoolData.special_school_information}
                            onChange={(e) => setEditExtraInformation(e.target.value)}
                          />
                          <TextField
                            label='School URL'
                            style={styles.inputLabel}
                            defaultValue={schoolData.slug}
                            onChange={(e) => setEditSchoolURL(e.target.value)}
                          />
                          <Autocomplete
                            disableClearable={true}
                            style={styles.inputLabel}
                            value={schoolData.background_color}
                            id='combo-box-demo'
                            options={[...new Set(partnerSchools.map((school) => school.background_color))]}
                            onChange={(e, color) => {
                              setEditBackgroundColor(color);
                            }}
                            renderInput={(params) =>
                              <TextField
                                {...params}
                                label='Background Color'
                                onChange={(e) => {
                                  setEditBackgroundColor(e.target.value);
                                }}
                              />}
                          />
                          <TextField
                            label='Program'
                            style={styles.inputLabel}
                            defaultValue={schoolData.program}
                            onChange={(e) => setEditProgram(e.target.value)}
                          />
                          <TextField
                            label='Pre-req Verbiage'
                            style={styles.inputLabel}
                            defaultValue={schoolData.pre_req_verbiage}
                            onChange={(e) => setEditPreReq(e.target.value)}
                          />
                          <div>
                            <Button
                              align='right'
                              onClick={(() => _saveEdits(schoolData.id))}
                            >
                              <DoneIcon />
                            </Button>
                            <Button align='right' onClick={_cancelEdits}>
                              <CloseIcon />
                            </Button>
                          </div>
                        </div>
                      }
                    </div>
                  ) : (
                    <>
                      <h4>{schoolData.school_name}</h4>
                      <Link to={{pathname: `https://portagelearning.com/partners/${schoolData.slug}`}} target='_blank'>{schoolData.school_name_large}</Link>
                      {schoolData.special_school_information &&
                        <p><b>Special information:</b> {schoolData.special_school_information}</p>
                      }
                      {!!admin.perms[DEVELOPER] &&
                        <div style={styles.partnerCard}>
                          <p style={styles.partnerInfo}><b>Route url:</b> {schoolData.slug}</p>
                          <p style={styles.partnerInfo}><b>Background color:</b> {schoolData.background_color}</p>
                          {/* <p><b>Image name:</b> {schoolData.img_url}</p> */}
                          {schoolData.program !== null && <p style={styles.partnerInfo}><b>Program:</b> {schoolData.program}</p>}
                          <p style={styles.partnerInfo}><b>State:</b> {schoolData.state}</p>
                          <Button
                            align='right'
                            style={styles.editButton}
                            onClick={(() => {
                              setEdit(true);
                              setEditCardId(schoolData.id);
                            })}
                          >
                            <CreateIcon />
                          </Button>
                        </div>
                      }
                    </>
                  )}
                </CardContent>
              </Card>
            ))}
          </div>
          )
        }
      </div>
    );
  };

  return (
    <div>
      <h2>Partner Schools</h2>
      <div style={{display: 'flex', alignItems: 'center'}}>
        <h3>Search partners:</h3>
        <TextField
          id='schoolSearch'
          style={styles.searchBox}
          variant='outlined'
          value={_searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          label='Search'
          margin='dense'
        />
        <Select
          onChange={(e) => setSchoolState(e.target.value)}
          value={_schoolState}
          style={{minWidth: '200px'}}
        >
          <MenuItem value='state'>All States</MenuItem>
          {states.map((s) => {
            return (
              <MenuItem key={s.short} value={s.short}>{s.name}</MenuItem>
            );
          })}
        </Select>
      </div>
      {partnerSchools.length > 0 &&
        <>
          {_renderPartners()}
        </>
      }
    </div>
  );
};

PartnerSchools.propTypes = {
  admin: PropTypes.object,
  createPartnerSchool: PropTypes.func,
  getAllCampuses: PropTypes.func,
  fetchPartnerSchools: PropTypes.func,
  patchPartnerSchools: PropTypes.func,
};

export default (PartnerSchools);

/*
 * Stevie Michalik
 * 2024-11-14
 * © Copyright 2024 NursingABC, Inc.  All Rights Reserved.
 */
import {
  Button,
  Collapse,
  IconButton,
  Paper,
  TableCell,
  TableRow,
} from '@mui/material';
import {
  sendAcademicScholarshipEmails,
  updateAcademicScholarshipStatus,
} from '../../../actions';
import KeyboardArrowDownIcon from 'mdi-react/KeyboardArrowDownIcon';
import KeyboardArrowUpIcon from 'mdi-react/KeyboardArrowUpIcon';
import {
  Link,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  capitalizeFirstLetter,
} from '../../../utilities';
import dayjs from 'dayjs';
import styles from './styles.js';
import {
  useDispatch,
} from 'react-redux';
import {
  useState,
} from 'react';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

const AcademicScholarshipTableRow = (props) => {
  const scholarship = props.scholarship;
  const campuses = props.campuses;
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const responses = scholarship.scholarship_responses;

  const _renderFirstColumnButton = (scholarship) => {
    let onPress;
    let innerText;

    if (scholarship.scholarship_status === 'created') {
      onPress = () => {
        dispatch(updateAcademicScholarshipStatus(scholarship, 'notified', () => {
          dispatch(sendAcademicScholarshipEmails(scholarship, 'notify'));
        }));
      };
      innerText = 'Notify';
    }
    if (scholarship.scholarship_status === 'notified') {
      onPress = () => {
        dispatch(sendAcademicScholarshipEmails(scholarship, 'remind'));
      };
      innerText = 'Remind';
    }
    if (scholarship.scholarship_status === 'applied') {
      onPress = () => {
        dispatch(updateAcademicScholarshipStatus(scholarship, 'approved', () => {
          dispatch(sendAcademicScholarshipEmails(scholarship, 'accept'));
        }));
      };
      innerText = 'Accept';
    }
    if (scholarship.scholarship_status === 'rejected') {
      onPress = () => {
        dispatch(updateAcademicScholarshipStatus(scholarship, 'applied'));
      };
      innerText = 'Reset';
    }

    if (scholarship.scholarship_status === 'created' || scholarship.scholarship_status === 'notified' || scholarship.scholarship_status === 'applied' || scholarship.scholarship_status === 'rejected') {
      return (
        <Button
          onClick={onPress}
          variant='contained'
          size='medium'
          color='secondary'
        >
          {innerText}
        </Button>
      );
    } else {
      return null;
    }
  };

  const _renderSecondColumnButton = (scholarship) => {
    let onPress;
    let innerText;

    if (scholarship.scholarship_status === 'applied') {
      onPress = () => {
        dispatch(updateAcademicScholarshipStatus(scholarship, 'rejected', () => {
          dispatch(sendAcademicScholarshipEmails(scholarship, 'reject'));
        }));
      };
      innerText = 'Reject';
    }

    if (scholarship.scholarship_status === 'applied') {
      return (
        <Button
          onClick={onPress}
          variant='contained'
          size='medium'
          color='primary'
        >
          {innerText}
        </Button>
      );
    } else {
      return null;
    }
  };

  return (
    <>
      <TableRow key={scholarship.student_id} hover={true}>
        <TableCell>
          <IconButton
            aria-label='expand row'
            size='small'
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>
          <Link to={`/students/${scholarship.student_id}`}>
            {scholarship.student_first_name} {scholarship.student_middle_name} {scholarship.student_last_name} {scholarship.student_suffix}
          </Link>
        </TableCell>
        <TableCell>{capitalizeFirstLetter(scholarship.scholarship_status)}</TableCell>
        <TableCell>{scholarship.student_email}</TableCell>
        <TableCell>{scholarship.student_preferred_school}</TableCell>
        <TableCell>{scholarship.total_credits}</TableCell>
        <TableCell>{dayjs(scholarship.most_recent_enrollment_conclusion).format('YYYY-MM-DD')}</TableCell>
        <TableCell>{dayjs(scholarship.updated_at).format('YYYY-MM-DD')}</TableCell>
        <TableCell>
          {_renderFirstColumnButton(scholarship)}
        </TableCell>
        <TableCell>
          {_renderSecondColumnButton(scholarship)}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={styles.tableCellHidden} colSpan={11}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <div style={styles.responsesContainer}>
              {responses && responses.length > 0
                ? responses.sort((a, b) => {
                  return dayjs(b.created_at).valueOf() - dayjs(a.created_at).valueOf();
                }).map((response) => {
                  const campus = campuses.filter((campus) => {
                    return campus.id === response.institution_campus_id;
                  })[0];

                  return (
                    <Paper key={response.id} style={styles.responseBox}>
                      <p><strong>Essay Response (submitted {dayjs.utc(response.created_at).format('MMMM Do YYYY, h:mm:ss a')})</strong></p>
                      <p>Institution Contact: {response.institution_contact_position} {response.institution_contact_first_name} {response.institution_contact_last_name} (email: {response.institution_contact_email})</p>
                      <p>Academic Advisor: {response.advisor_first_name} {response.advisor_last_name} (email: {response.advisor_email})</p>
                      <p>Institution: {campus.name} (phone: {response.institution_phone})</p>
                      <p>Institution Student ID Number: {response.institution_student_id}</p>
                      <p>Essay Text: {response.essay_response}</p>
                    </Paper>
                  );
                })
                : <p>No scholarship responses for this student</p>
              }
            </div>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

AcademicScholarshipTableRow.propTypes = {
  campuses: PropTypes.arrayOf(
    PropTypes.object,
  ).isRequired,
  scholarship: PropTypes.object.isRequired,
};

export default AcademicScholarshipTableRow;

/*
 * Nick Lewyn
 * 2024-12-12
 * © Copyright 2024 NursingABC, Inc.  All Rights Reserved.
 */

import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  useEffect,
  useState,
} from 'react';
import FacultyDashboardDataView from './DataView/index';
import FacultySearchAutoComplete from '../SearchAutoComplete';
import {
  fetchInstructors,
} from '../../../actions';

const FacultyDashboard = () => {
  const dispatch = useDispatch();
  const instructors = useSelector((state) => state.instructors);
  const user = useSelector((state) => state.admin);
  const deptChairs = useSelector((state) => state.deptChairs);
  const loadingStates = useSelector((state) => state.loadingStates);
  const [_selectedInstructorId, setSelectedInstructorId] = useState(null);

  useEffect(() => {
    dispatch(fetchInstructors({dept_chair: 1}, 'fetchDeptChairs'));
  }, [dispatch]);

  useEffect(() => {
    let instructorParam = {disabled: 0};
    const deptChairObject = (deptChairs.filter((instructor) => instructor.email === user.email && instructor.disabled === 0));
    if (`${user.first_name}${user.last_name}` !== 'DianaRice') {
      const deptChairUser = deptChairObject.length > 0 ? deptChairObject[0] : null;
      if (!deptChairUser) {
        instructorParam = {...instructorParam, manager_id: user.id};
      } else {
        instructorParam = {...instructorParam, manager_id: deptChairUser.id};
      }
    }
    dispatch(fetchInstructors(instructorParam));
  }, [deptChairs]);

  return (
    <>
      {(loadingStates.fetchInstructors && loadingStates.fetchDeptChairs) &&
        <p>loading..</p>
      }
      {(!loadingStates.fetchInstructors && !loadingStates.fetchDeptChairs) &&
      <FacultySearchAutoComplete
        instructors={instructors}
        selectCallBack={(instructorObjectSelected) => {
          if (instructorObjectSelected) {
            setSelectedInstructorId(instructorObjectSelected);
          } else {
            setSelectedInstructorId(null);
          }
        }}
      />
      }
      <FacultyDashboardDataView selectedInstructor={_selectedInstructorId}/>
    </>
  );
};

export default FacultyDashboard;

/*
 * Ryan O'Dowd
 * 2019-01-21
 * © Copyright 2024 NursingABC, Inc.  All Rights Reserved.
 */
import dayjs from 'dayjs';
import styles from './styles';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

const Footer = () => {
  return (
    <div style={styles.container}>
      <p>{`Copyright © ${dayjs().utc().year()} NursingABC, Inc. All rights reserved.`}</p>
    </div>
  );
};

export default Footer;

/*
 * Andrew Moskal
 * 2024-10-11
 * © Copyright 2024 NursingABC, Inc.  All Rights Reserved.
 */

const styles = {
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  selectContainer: {
    padding: '10px',
  },
  paper: {
    padding: '10px',
  },
};

export default styles;

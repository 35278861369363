/*
 * Ally Zernick
 * 2024-07-16
 * © Copyright 2020 NursingABC, Inc.  All Rights Reserved.
 */

import Globals from '../../Globals';

const styles = {
  autocomplete: {
    width: '250px',
    textAlign: 'left',
  },
  fullCoverageContainer: {
    padding: '15px',
    display: 'flex',
  },
  fullCoverageItem: {
    height: 'auto',
    marginRight: '10px',
  },
  courseInfoContainer: {
    border: `1px solid ${Globals.colors.lightGray}`,
    borderRadius: '5px',
    padding: '15px',
  },
  courseInfo: {
    height: 'auto',
    display: 'flex',
    margin: '10px 15px 50px',
    gap: '10px',
  },
  courseInfoHeader: {
    display: 'flex',
    marginLeft: '15px',
    fontWeight: 'bold',
    gap: '10px',
  },
  courseInfoObj: {
    width: `${100 / 7}%`,
    height: 'auto',
    marginBottom: '10px',
  },
  steppers: {
    maxWidth: '50px',
    marginLeft: '5px',
  },
  studentRange: {
    display: 'flex',
    justifyContent: 'space-between',
    border: `1px solid ${Globals.colors.lightGray}`,
    borderRadius: '5px',
    padding: '0px 10px',
    marginBottom: '5px',
  },
  numberInput: {
    maxWidth: '25px',
    marginLeft: '10px',
    border: '1px solid black',
    borderRadius: '5px',
    padding: '2.5px 5px',
    fontSize: '12px',
  },
  multiInput: {
    display: 'flex',
    flexDirection: 'column',
  },
  multiInputIndividual: {
    display: 'flex',
    marginBottom: '20px',
    gap: '10px',
  },
  multiCourseInfoObj: {
    height: 'auto',
    marginRight: '0px',
  },
  courseInfoObjCoverage: {
    height: 'auto',
    marginBottom: '10px',
  },
  horizontalLine: {
    marginBottom: '30px',
  },
  editButtons: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  coverageTables: {
    marginTop: '10px',
  },
  warningText: {
    color: Globals.colors.error,
    marginTop: '-20px',
    fontSize: '14px',
  },
  loading: {
    display: 'flex',
    color: Globals.colors.primary,
    justifyContent: 'center',
    gap: '15px',
  },
  tableHead: {
    display: 'flex',
  },
};

export default styles;

/*
 * Ryan O'Dowd
 * 2021-03-15
 * © Copyright 2018 NursingABC, Inc.  All Rights Reserved.
 */
import {
  Button,
} from '@mui/material';
import {
  fetchSchoolRelationsTranscriptRequests,
} from '../../actions';
import styles from './styles';
import {
  useDispatch,
} from 'react-redux';

const NewContacts = () => {
  const dispatch = useDispatch();

  return (
    <div style={styles.container}>
      <h3>Click the button below to download transcript requests for the past week.</h3>
      <Button
        color='primary'
        variant='contained'
        onClick={() => dispatch(fetchSchoolRelationsTranscriptRequests())}
      >
        Download Transcripts Report
      </Button>
    </div>
  );
};

export default NewContacts;

/*
 * Nathaniel Padgett
 * 2020-09-24
 * © Copyright 2024 NursingABC, Inc.  All Rights Reserved.
 */
const styles = {
  formControlWrapper: {
    width: 150,
    marginRight: 5,
    marginBottom: 10,
  },
  buttonStyles: {
    marginLeft: '10px',
  },
};

export default styles;

/*
 * Ryan O'Dowd
 * 2020-05-13
 * © Copyright 2024 NursingABC, Inc.  All Rights Reserved.
 */
import Globals from '../../Globals';

const styles = {
  container: {
    width: '100%',
    textAlign: 'left',
  },
  sectionWrapper: { // @TODO: abstract with user management
    display: 'flex',
    flexDirection: 'column',
  },
  sectionTitle: { // @TODO:abstract with user management
    color: Globals.colors.accent,
  },
  sectionDescription: { // @TODO: abstract with user management
    color: Globals.colors.primary,
    fontSize: 12,
  },
  tableWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  tableToolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  leftItems: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  rightItems: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

export default styles;

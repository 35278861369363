/*
 * Ally Zernick
 * 2024-06-07
 * © Copyright 2024 NursingABC, Inc.  All Rights Reserved.
 */
import {
  Autocomplete,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
} from '@mui/material';
import {
  DatePicker,
  LocalizationProvider,
} from '@mui/x-date-pickers';
import React, {
  useEffect,
  useState,
} from 'react';
import {
  createProfessorVacationCoverage,
  fetchActiveEnrollmentsForInstructor,
  fetchCourses,
  fetchCoursesForDepartmentChair,
  fetchInstructors,
  fetchVacationCoverage,
  patchVacationCoverage,
  removeVacationCoverage,
} from '../../actions';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  AdapterDayjs,
} from '@mui/x-date-pickers/AdapterDayjs';
import CloseIcon from '@mui/icons-material/Close';
import CreateIcon from '@mui/icons-material/Create';
import DoneIcon from '@mui/icons-material/Done';
import Globals from '../../Globals';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import styles from './styles';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(isBetween);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

const VacationCoverage = () => {
  const [_selectedProfessor, setSelectedProfessor] = useState('');
  const [_selectedIndividualProfessor, setSelectedIndividualProfessor] = useState('');
  const [_coursesTaught, setCoursesTaught] = useState();
  const [_coverageWeeks, setCoverageWeeks] = useState(1);
  const [_coverageCourse, setCoverageCourse] = useState();
  const [_currentCoverage, setCurrentCoverage] = useState([]);
  const [_editRow, setEditRow] = useState(0);
  const [_editCoverage, setEditCoverage] = useState({
    start_date: undefined, end_date: undefined,
  });
  const [_fullCoverage, setFullCoverage] = useState({
    full_start_date: dayjs(), full_end_date: dayjs(),
  });
  const [_totalPageNum, setTotalPageNum] = useState(0);
  const [_totalRowsPerPage, setTotalRowsPerPage] = useState(5);
  const [_searchText, setSearchText] = useState('');
  const [_adminInstructors, setAdminInstructors] = useState();
  const [_viewOpposite, setViewOpposite] = useState(false);
  const [_sortingMonth, setSortingMonth] = useState(dayjs().format('M') - 1);
  const [_monthOrYear, setMonthOrYear] = useState('month');
  const [_sortKey, setSortKey] = useState('last_name');
  const [_sortDirection, setSortDirection] = useState('asc');
  const admin = useSelector((state) => state.admin);
  const departmentCourses = useSelector((state) => state.departmentChairCourses);
  const courses = useSelector((state) => state.courses);
  const instructors = useSelector((state) => state.instructors);
  const activeEnrollments = useSelector((state) => state.activeEnrollments);
  const vacationCoverage = useSelector((state) => state.vacationCoverage);
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchInstructors());
    dispatch(fetchVacationCoverage());
    dispatch(fetchCourses());
  }, []);

  useEffect(() => {
    if (instructors.length !== undefined) {
      const departmentChair = instructors.find((i) => i.email === admin.email);
      if (departmentChair !== undefined) {
        dispatch(fetchCoursesForDepartmentChair(admin.username));
      } else {
        instructors.sort((a, b) => a.last_name.localeCompare(b.last_name));
        setAdminInstructors(instructors);
      }
    }
  }, [instructors]);

  useEffect(() => {
    const validInstructors = [];
    instructors.length > 0 && instructors.map((inst) => {
      if (inst.support) {
        validInstructors.push(inst);
      }
      inst.courses_taught.map((ct) => {
        if (departmentCourses.find((dc) => dc.code === ct.code) !== undefined) {
          if (validInstructors.indexOf(inst) === -1) {
            validInstructors.push(inst);
          }
        }
      });
    });
    setAdminInstructors(validInstructors);
  }, [departmentCourses]);

  const _resetVars = () => {
    _currentCoverage.length = 0;
    _renderDefaultCoverage(1);
    setCoverageWeeks(1);
    setEditRow(0);
    setEditCoverage({
      start_date: undefined, end_date: undefined,
    });
  };

  const _renderStudentCoverage = (c, professors) => {
    const alphabet = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
    const enrollments = activeEnrollments.filter((enrollment) => enrollment.course_id === c.id)[0]?.active_enrollments;
    const coverage = [];

    if (enrollments.length === 0) {
      const splits = parseInt(alphabet.length / professors.length);
      professors.map((prof, i) => {
        coverage.push({
          instructor: prof.instructor_name,
          instructor_id: prof.instructor_id,
          alphabet_begin: i === 0 ? 'A' : alphabet[splits * i],
          alphabet_end: professors.length === (i + 1) ? 'Z' : alphabet[splits * (i + 1)],
        });
      });
    } else {
      try {
        professors.map((prof, i) => {
          const slice = enrollments.length / professors.length;
          const splitEnrollments = Object.values(enrollments).slice(slice * i, slice * (i + 1));
          coverage.push({
            instructor: prof.instructor_name,
            instructor_id: prof.instructor_id,
            alphabet_begin: i === 0 ? 'A' : splitEnrollments[0].last_name.substring(0, 1),
            alphabet_end: professors.length === (i + 1) ? 'Z' : splitEnrollments[splitEnrollments.length - 1].last_name.substring(0, 1),
          });
        });
      } catch {
        return <p style={{color: Globals.colors.error}}>Assigning more instructors than active students is not allowed unless there are no active students.</p>;
      }
    }

    coverage.length > 1 && coverage.map((cover, i) => {
      if (cover.alphabet_end !== 'Z') {
        coverage[i + 1].alphabet_begin = alphabet.at(alphabet.indexOf(cover.alphabet_end) + 1);
      }
    });

    return (
      <>
        {coverage.map((cover) => {
          return <p style={{lineHeight: 1.5, marginTop: '0px'}} key={cover.instructor_id}>{cover.instructor}<br />Cover: {cover.alphabet_begin} - {cover.alphabet_end}</p>;
        })}
        <Button
          variant='contained'
          color='primary'
          onClick={() => {
            const currCoverage = _currentCoverage.find((c) => c.professors[0].instructor_id === coverage[0].instructor_id && !c.already_assigned);
            coverage.map((prof) => {
              dispatch(createProfessorVacationCoverage({
                start_date: dayjs(currCoverage.start_date).format('YYYY-MM-DD'),
                end_date: dayjs(currCoverage.end_date).format('YYYY-MM-DD'),
                instructor_id: _selectedProfessor.instructor_id,
                coverage_instructor_id: prof.instructor_id,
                course_id: _coverageCourse,
                true_coverage: currCoverage.true_coverage,
                double_days: 0,
                created_by: admin.email.substring(0, admin.email.indexOf('@')),
                updated_by: admin.email.substring(0, admin.email.indexOf('@')),
                created_at: dayjs().format('YYYY-MM-DD HH:mm:ss'),
                updated_at: dayjs().format('YYYY-MM-DD HH:mm:ss'),
                coverage_range: `${coverage.find((c) => c.instructor_id === prof.instructor_id).alphabet_begin} - ${coverage.find((c) => c.instructor_id === prof.instructor_id).alphabet_end}`,
                full_coverage_end_date: dayjs(_fullCoverage.full_end_date).format('YYYY-MM-DD'),
              }));
            });
            _handleInputChange(currCoverage.id, 'already_assigned', true);
          }}
        >
          Assign Coverage
        </Button>
      </>
    );
  };

  const _handleInputChange = (index, key, value) => {
    setCurrentCoverage(_currentCoverage.map((c, i) => {
      if (i === _currentCoverage.findIndex((cover) => cover.id === index)) {
        if (key === 'end_date' && c.start_date.isSameOrAfter(value)) {
          c[key] = c.start_date;
        }
        if (i === 0 && key === 'start_date') {
          c[key] = _fullCoverage.full_start_date;
        } else if (i === _currentCoverage.length - 1 && key === 'end_date') {
          c[key] = _fullCoverage.full_end_date;
        } else if (i !== 0 && key === 'start_date') {
          const priorCoverageIndex = _currentCoverage.findIndex((priorCover) => priorCover.id === (index - 1));
          if (priorCoverageIndex >= 0) {
            c[key] = dayjs(_currentCoverage[priorCoverageIndex].end_date).add(1, 'day');
          }
        } else {
          if (key === 'end_date' && _currentCoverage.length > 1 && i !== _currentCoverage.length - 1) {
            const newValue = dayjs(value);
            _currentCoverage[index].start_date = newValue.add(1, 'day');
            setCurrentCoverage(_currentCoverage[index]);
          }
          c[key] = value;
        }
        return c;
      } else {
        return c;
      }
    }));
  };

  const _renderDefaultCoverage = (num) => {
    if (num < _currentCoverage.length) {
      setCurrentCoverage(_currentCoverage.slice(0, num));
      return;
    }
    let defaultCoverage = [];
    for (let i = 0; i <= num; i++) {
      if (_currentCoverage[i]) {
        continue;
      }
      defaultCoverage = [
        ..._currentCoverage.slice(0, i),
        {
          id: i,
          start_date: _fullCoverage.full_start_date,
          end_date: _fullCoverage.full_end_date,
          professors: [],
          double_days: 0,
          true_coverage: 1,
          already_assigned: false,
        },
        ..._currentCoverage.slice(i),
      ];
      setCurrentCoverage(defaultCoverage);
    }
  };

  const _handlePortagePrepCourses = (course) => {
    let assignmentProfessors = instructors.filter((i) => i.id !== _selectedProfessor.instructor_id && (i.support === 1 || i.courses_taught.filter((ct) => ct.department_id === course.department_id || (course.department_id === 22 && course.title.toLowerCase().includes(ct.code.substring(0, 3)))).length > 0));
    if (course.id === 16 || course.id === 7) {
      assignmentProfessors = assignmentProfessors.concat(instructors.filter((i) => i.id === 38704 || i.id === 33375 || i.id === 19271 || i.id === 581));
    }
    if (course.id === 30) {
      assignmentProfessors = assignmentProfessors.concat(instructors.filter((i) => i.id === 9830 || i.id === 33375));
    }

    return Object.values(assignmentProfessors).map((a) => ({
      instructor_id: a.id,
      instructor_name: `${a.first_name} ${a.last_name}`,
    }));
  };

  const _renderDateRanges = (c) => {
    return (
      <>
        {_currentCoverage.map((week, index) => {
          const preexistingCoverage = vacationCoverage.filter((v) => v.instructor_id === _selectedProfessor.instructor_id && v.course_id === c.id && v.disabled === 0 && ((dayjs(v.start_date).utc().format('YYYY-MM-DD') <= dayjs(week.start_date).utc().format('YYYY-MM-DD') && dayjs(week.start_date).utc().format('YYYY-MM-DD') <= dayjs(v.end_date).utc().format('YYYY-MM-DD')) || (dayjs(v.start_date).utc().format('YYYY-MM-DD') <= dayjs(week.end_date).utc().format('YYYY-MM-DD') && dayjs(week.start_date).utc().format('YYYY-MM-DD') <= dayjs(v.end_date).utc().format('YYYY-MM-DD'))));
          return (
            <>
              <div key={week.id} style={styles.multiInputIndividual}>
                <div style={styles.multiCourseInfoObj}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      disablePast={true}
                      style={{marginBottom: '5px'}}
                      label='Start Date'
                      format='YYYY/MM/DD'
                      value={week.start_date}
                      onChange={(e) => _handleInputChange(week.id, 'start_date', e)}
                      inputVariant='outlined'
                    />
                  </LocalizationProvider>
                </div>
                <div style={styles.multiInput}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      disablePast={true}
                      style={styles.multiCourseInfoObj}
                      label='End Date'
                      format='YYYY/MM/DD'
                      value={week.end_date}
                      onChange={(e) => _handleInputChange(week.id, 'end_date', e)}
                      inputVariant='outlined'
                    />
                  </LocalizationProvider>
                  <FormControlLabel
                    control={
                      <Checkbox
                        defaultChecked={true}
                        onClick={() => _handleInputChange(week.id, 'true_coverage', !week.true_coverage)}
                      />
                    }
                    label='True coverage'
                  />
                </div>
                <Autocomplete
                  size={'small'}
                  multiple
                  disableCloseOnSelect
                  style={styles.autocomplete}
                  id='combo-box-demo'
                  options={_handlePortagePrepCourses(c)}
                  getOptionLabel={(prof) => prof.instructor_name}
                  isOptionEqualToValue={(option, value) => option.instructor_id === value.instructor_id}
                  onChange={(e, prof) => _handleInputChange(week.id, 'professors', prof)}
                  renderInput={(params) =>
                    <TextField
                      {...params}
                      label='Professor(s)'
                      variant='outlined'
                    />}
                />
                <div style={styles.courseInfoObjCoverage}>
                  {week?.professors.length > 0 && preexistingCoverage.length < 1 &&
                    _renderStudentCoverage(c, week.professors)
                  }
                </div>
              </div>
              {preexistingCoverage.length >= 1 && <p style={styles.warningText}>Warning: Coverage already exists within this date range. Dates: {dayjs(preexistingCoverage[0].start_date).utc().format('YYYY-MM-DD')} to {dayjs(preexistingCoverage[0].end_date).utc().format('YYYY-MM-DD')}</p>}
              {index !== _currentCoverage.length - 1 && <hr style={styles.horizontalLine} width='100%' />}
            </>
          );
        })}
      </>
    );
  };

  if (_adminInstructors?.length === undefined) {
    return (
      <div style={styles.loading}>
        <h4>Loading</h4>
        <CircularProgress />
      </div>
    );
  }

  const filteredInstructors = _adminInstructors.filter((prof) => `${prof.first_name} ${prof.last_name}`.toLowerCase().includes(_searchText)).map((prof) => {
    const startDate = _monthOrYear === 'year' ? dayjs().startOf('year') : dayjs().month(_sortingMonth).startOf('month');
    const endDate = _monthOrYear === 'year' ? dayjs().endOf('year') : dayjs().month(_sortingMonth).endOf('month');
    const usedDates = [];
    const coverageForInstructor = vacationCoverage?.filter((cover) => cover.coverage_instructor_id === prof.id && cover.true_coverage === 1 && (dayjs(cover.start_date).isBetween(startDate, endDate) || dayjs(cover.end_date).isBetween(startDate, endDate)));
    coverageForInstructor.map((coverage) => {
      const coverageRange = dayjs(coverage.end_date).diff(dayjs(coverage.start_date), 'day') + 1;

      for (let index = 0; index < coverageRange; index++) {
        const mutableDate = index === 0 ? dayjs(coverage.start_date) : dayjs(coverage.start_date).add(index, 'day');
        if (!usedDates.includes(mutableDate.format('YYYY-MM-DD')) && mutableDate.day() < 5) {
          usedDates.push(mutableDate.format('YYYY-MM-DD'));
        }
      }
    });

    prof.coverage_days = usedDates.length;
    return prof;
  });
  const emptyRows = _totalRowsPerPage - Math.min(_totalRowsPerPage, _adminInstructors.length - _totalPageNum * _totalRowsPerPage);
  return (
    <div>
      <h2>Vacation Coverage</h2>
      <p>Select a professor in order to create vacation coverage for them.</p>
      <div style={styles.fullCoverageContainer}>
        <div style={styles.fullCoverageItem}>
          <Autocomplete
            disableClearable
            style={styles.autocomplete}
            id='combo-box-demo'
            options={Object.values(_adminInstructors).sort((a, b) => b[9] - a[9]).filter((i) => i.courses_taught.length !== 0).map((a) => ({
              instructor_id: a.id,
              instructor_name: `${a.first_name} ${a.last_name}`,
              courses_taught: a.courses_taught,
            }))}
            getOptionLabel={(prof) => prof.instructor_name}
            isOptionEqualToValue={(option, value) => option.instructor_id === value.instructor_id}
            onChange={(e, prof) => {
              setSelectedProfessor(prof);
              setCoursesTaught(prof.courses_taught);
              dispatch(fetchActiveEnrollmentsForInstructor(prof.instructor_id));
            }}
            renderInput={(params) =>
              <TextField
                {...params}
                label='Professor'
                variant='outlined'
              />}
          />
        </div>
        <div style={styles.fullCoverageItem}>
          <div style={styles.multiInput}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                disablePast={true}
                style={{marginBottom: '5px'}}
                label='Full Coverage Start Date'
                format='YYYY-MM-DD'
                value={_fullCoverage.full_start_date}
                onChange={(e) => {
                  if (e.isSameOrBefore(_fullCoverage.full_end_date)) {
                    setFullCoverage({
                      ..._fullCoverage,
                      full_start_date: e,
                    });
                  }
                  if (_fullCoverage.full_end_date.isSameOrBefore(e)) {
                    setFullCoverage({
                      ..._fullCoverage,
                      full_start_date: e,
                      full_end_date: e,
                    });
                  }
                }}
                inputVariant='outlined'
              />
            </LocalizationProvider>
          </div>
        </div>
        <div style={styles.fullCoverageItem}>
          <div style={styles.multiInput}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                disablePast={true}
                style={styles.multiCourseInfoObj}
                label='Full Coverage End Date'
                format='YYYY-MM-DD'
                value={_fullCoverage.full_end_date}
                onChange={(e) => {
                  if (e.isSameOrAfter(_fullCoverage.full_start_date)) {
                    setFullCoverage({
                      ..._fullCoverage,
                      full_end_date: e,
                    });
                  }
                }
                }
                inputVariant='outlined'
              />
            </LocalizationProvider>
          </div>
        </div>
      </div>
      {_coursesTaught &&
        <div>
          <div style={styles.courseInfoHeader}>
            <p style={styles.courseInfoObj}>Course</p>
            <p style={{...styles.courseInfoObj, textAlign: 'center'}}>Active Students</p>
            <p style={styles.courseInfoObj}>Start Date</p>
            <p style={styles.courseInfoObj}>End Date</p>
            <p style={styles.courseInfoObj}>Professor(s)</p>
            <p style={styles.courseInfoObj} />
          </div>
          <div style={styles.courseInfoContainer}>
            {_coursesTaught.map((c) => {
              return (
                <div key={c.id} style={styles.courseInfo}>
                  {_coverageCourse === c.id ? (
                    <>
                      <span style={styles.courseInfoObj}>
                        <p><b>{c.code.includes('-') ? c.title + c.code.substring(c.code.indexOf('-')) : c.title}</b></p>
                        <div style={{marginTop: '7px', display: 'flex'}}>Coverage period(s):
                          <input
                            type='number'
                            value={_coverageWeeks}
                            onInput={(e) => {
                              setCoverageWeeks(e.target.value);
                              _renderDefaultCoverage(e.target.value);
                            }}
                            style={styles.numberInput}
                            min='1'
                          />
                        </div>
                      </span>
                      {activeEnrollments.length > 0 &&
                        <p style={{...styles.courseInfoObj, textAlign: 'center'}}>
                          {activeEnrollments.filter((enrollment) => enrollment.course_id === c.id)[0]?.active_enrollments?.length}
                        </p>}
                      <div style={styles.multiInput}>
                        {_renderDateRanges(c)}
                      </div>
                    </>
                  ) : (
                    <>
                      <p style={styles.courseInfoObj}>
                        {c.code.includes('-') ? c.title + c.code.substring(c.code.indexOf('-')) : c.title}
                      </p>
                      {activeEnrollments.length > 0 &&
                        <p style={{...styles.courseInfoObj, textAlign: 'center'}}>
                          {activeEnrollments.filter((enrollment) => enrollment.course_id === c.id)[0]?.active_enrollments?.length}
                        </p>
                      }
                      <p style={styles.courseInfoObj} />
                      <p style={styles.courseInfoObj} />
                      <p style={styles.courseInfoObj} />
                      <p style={styles.courseInfoObj} />
                      <Button
                        variant='contained'
                        color='primary'
                        key={_currentCoverage}
                        onClick={() => {
                          setCoverageCourse(c.id);
                          _resetVars();
                        }}
                      >
                        Create Coverage
                      </Button>
                    </>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      }
      <hr width='100%' style={{margin: '50px 0'}} />
      {vacationCoverage.length > 0 &&
        <>
          <div>
            <h2>View Individual Professor Coverage</h2>
            <p>
              Please note that this table displays active/future coverage only.<br /><br />
              {`Selecting the switch labeled "Display Courses Professor is Covering" will show the courses the professor is covering rather than their courses being covered.`}
            </p>
            <div style={{display: 'flex', alignItems: 'center'}}>
              <Autocomplete
                disableClearable
                style={styles.autocomplete}
                id='combo-box-demo'
                options={Object.values(_adminInstructors).filter((i) => i.courses_taught.length !== 0).map((a) => ({
                  instructor_id: a.id,
                  instructor_name: `${a.first_name} ${a.last_name}`,
                  courses_taught: a.courses_taught,
                }))}
                getOptionLabel={(prof) => prof.instructor_name}
                isOptionEqualToValue={(option, value) => option.instructor_id === value.instructor_id}
                onChange={(e, prof) => setSelectedIndividualProfessor(prof)}
                renderInput={(params) =>
                  <TextField
                    {...params}
                    label='Professor'
                    variant='outlined'
                  />}
              />
              <FormControlLabel
                control={<Switch checked={_viewOpposite} onChange={() => setViewOpposite(!_viewOpposite)} />}
                label='Display Courses Professor is Covering'
                style={{marginLeft: '10px'}}
              />
            </div>
            <Paper>
              {_selectedIndividualProfessor !== '' &&
                <Table style={styles.coverageTables}>
                  <TableHead>
                    <TableRow>
                      <TableCell align='left'>Actual Professor</TableCell>
                      <TableCell align='left'>Coverage Professor</TableCell>
                      <TableCell align='left'>Course</TableCell>
                      <TableCell align='left'>Start Date</TableCell>
                      <TableCell align='left'>End Date</TableCell>
                      <TableCell align='left'>Full Coverage End Date</TableCell>
                      <TableCell align='left'>True Coverage</TableCell>
                      <TableCell align='left'>Student Coverage</TableCell>
                      <TableCell align='left' />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {vacationCoverage.filter((c) => c.disabled === 0 && (_viewOpposite ? c.coverage_instructor_id === _selectedIndividualProfessor.instructor_id : c.instructor_id === _selectedIndividualProfessor.instructor_id)).map((coverage) => {
                      const originalProfessor = `${_adminInstructors.find((i) => i.id === coverage.instructor_id).first_name} ${_adminInstructors.find((i) => i.id === coverage.instructor_id).last_name}`;
                      const coverageProfessor = `${instructors.find((i) => i.id === coverage.coverage_instructor_id).first_name} ${instructors.find((i) => i.id === coverage.coverage_instructor_id).last_name}`;
                      const course = Object.values(courses).find((c) => c.id === coverage.course_id);
                      const courseTitle = course.code.includes('-') ? course.title + course.code.substring(course.code.indexOf('-')) : course.title;
                      return (
                        <React.Fragment key={coverage.id}>
                          <TableRow
                            hover
                          >
                            {_editRow === coverage.id ? (
                              <>
                                <TableCell align='left'>{originalProfessor}</TableCell>
                                <TableCell align='left'>{coverageProfessor}</TableCell>
                                <TableCell align='left'>{courseTitle}</TableCell>
                                <TableCell align='left'>
                                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                      style={styles.multiCourseInfoObj}
                                      label='Start Date'
                                      format='YYYY-MM-DD'
                                      value={_editCoverage.start_date !== undefined ? dayjs(_editCoverage.start_date).utc() : dayjs(coverage.start_date).utc()}
                                      onChange={(e) => {
                                        if (e.isSameOrBefore(_editCoverage.end_date !== undefined ? dayjs(_editCoverage.end_date).utc() : dayjs(coverage.start_date).utc())) {
                                          setEditCoverage({
                                            ..._editCoverage,
                                            start_date: e.format('YYYY-MM-DD'),
                                          });
                                        }
                                      }}
                                      inputVariant='outlined'
                                    />
                                  </LocalizationProvider>
                                </TableCell>
                                <TableCell align='left'>
                                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                      style={styles.multiCourseInfoObj}
                                      label='End Date'
                                      format='YYYY-MM-DD'
                                      value={_editCoverage.end_date !== undefined ? dayjs(_editCoverage.end_date).utc() : dayjs(coverage.end_date).utc()}
                                      onChange={(e) => {
                                        if (e.isSameOrBefore(dayjs(coverage.full_coverage_end_date).utc()) && e.isSameOrAfter(_editCoverage.start_date !== undefined ? dayjs(_editCoverage.start_date).utc() : dayjs(coverage.start_date).utc())) {
                                          setEditCoverage({
                                            ..._editCoverage,
                                            end_date: e.format('YYYY-MM-DD'),
                                          });
                                        }
                                      }}
                                      inputVariant='outlined'
                                    />
                                  </LocalizationProvider>
                                </TableCell>
                                <TableCell align='left'>{dayjs(coverage.full_coverage_end_date).utc().format('YYYY-MM-DD')}</TableCell>
                                <TableCell align='left'>{coverage.true_coverage ? 'Yes' : 'No'}</TableCell>
                                <TableCell align='left'>{coverage.coverage_range}</TableCell>
                                <TableCell align='left' style={styles.editButtons}>
                                  <div style={{display: 'flex'}}>
                                    <Button
                                      disabled={_editCoverage.end_date === undefined && _editCoverage.start_date === undefined}
                                      onClick={(() => {
                                        dispatch(patchVacationCoverage(coverage.id, _editCoverage));
                                        _resetVars();
                                      })}
                                    >
                                      <DoneIcon />
                                    </Button>
                                    <Button onClick={() => _resetVars()}>
                                      <CloseIcon />
                                    </Button>
                                  </div>
                                  <Button
                                    onClick={() => dispatch(removeVacationCoverage(coverage.id))}
                                    disabled={dayjs(coverage.start_date).utc().format('YYYY-MM-DD') <= dayjs().utc().format('YYYY-MM-DD') && dayjs().utc().format('YYYY-MM-DD') <= dayjs(coverage.end_date).utc().format('YYYY-MM-DD')}
                                    variant='contained'
                                    color='primary'
                                  >
                                    Unassign
                                  </Button>
                                </TableCell>
                              </>
                            ) : (
                              <>
                                <TableCell align='left'>{originalProfessor}</TableCell>
                                <TableCell align='left'>{coverageProfessor} </TableCell>
                                <TableCell align='left'>{courseTitle}</TableCell>
                                <TableCell align='left'>{dayjs(coverage.start_date).utc().format('YYYY-MM-DD')}</TableCell>
                                <TableCell align='left'>{dayjs(coverage.end_date).utc().format('YYYY-MM-DD')}</TableCell>
                                <TableCell align='left'>{dayjs(coverage.full_coverage_end_date).utc().format('YYYY-MM-DD')}</TableCell>
                                <TableCell align='left'>{coverage.true_coverage ? 'Yes' : 'No'}</TableCell>
                                <TableCell align='left'>{coverage.coverage_range}</TableCell>
                                <TableCell align='left'>
                                  <Button
                                    align='right'
                                    onClick={() => setEditRow(coverage.id)}
                                  >
                                    <CreateIcon />
                                  </Button>
                                </TableCell>
                              </>
                            )}
                          </TableRow>
                        </React.Fragment>
                      );
                    })}
                  </TableBody>
                </Table>
              }
            </Paper>
          </div>
          <hr width='100%' style={{margin: '50px 0'}} />
        </>
      }
      {vacationCoverage.length > 0 &&
        <div>
          <h2>View Total Professor Coverage</h2>
          <p>Please note that these values are for
            the {_monthOrYear === 'month' ? 'selected month' : 'current year'} only.</p>
          <Paper>
            <div style={styles.tableHead}>
              <TextField
                placeholder='Search'
                variant='outlined'
                style={{margin: '10px'}}
                onChange={(e) => setSearchText(e.target.value.toLowerCase())}
              />
              <RadioGroup
                row
                aria-label='position'
                name='position'
                defaultValue={'month'}
                onChange={(e) => setMonthOrYear(e.target.value)}
              >
                <FormControlLabel
                  value={'year'}
                  control={<Radio color='primary' />}
                  label={'Year'}
                />
                <FormControlLabel
                  value={'month'}
                  control={<Radio color='primary' />}
                  label={'Month'}
                />
              </RadioGroup>
              {_monthOrYear === 'month' &&
                <Select
                  onChange={(e) => setSortingMonth(e.target.value - 1)}
                  defaultValue={dayjs().format('M')}
                  style={{margin: '10px'}}
                  variant='outlined'
                >
                  {months.map((m, i) => {
                    return <MenuItem key={i + 1} value={i + 1}>{m}</MenuItem>;
                  })}
                </Select>
              }
            </div>
            <Table
              id='totalCoverage'
              size='medium'
            >
              <TableHead>
                <TableRow>
                  <TableCell>
                    <TableSortLabel
                      active={_sortKey === 'last_name'}
                      direction={_sortDirection}
                      onClick={() => {
                        setSortKey('last_name');
                        setSortDirection(_sortDirection === 'asc' ? 'desc' : 'asc');
                      }}
                    >
                      Professor Name
                    </TableSortLabel>
                  </TableCell>
                  <TableCell>
                    <TableSortLabel
                      active={_sortKey === 'coverage_days'}
                      direction={_sortDirection}
                      onClick={() => {
                        setSortKey('coverage_days');
                        setSortDirection(_sortDirection === 'asc' ? 'desc' : 'asc');
                      }}
                    >
                      Total Coverage Days
                    </TableSortLabel>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredInstructors.length > 0 && filteredInstructors.sort((a, b) => {
                  let valueOfA = a[_sortKey];
                  let valueOfB = b[_sortKey];
                  if (!isNaN(Date.parse(valueOfA)) && !isNaN(Date.parse(valueOfB))) {
                    valueOfA = Date.parse(valueOfA);
                    valueOfB = Date.parse(valueOfB);

                    if (_sortDirection === 'asc') {
                      return valueOfA - valueOfB;
                    }
                    return valueOfB - valueOfA;
                  }
                  if (typeof valueOfA === 'string') {
                    valueOfA = valueOfA.toLowerCase().trim();
                    valueOfB = valueOfB.toLowerCase().trim();

                    if (_sortDirection === 'asc') {
                      return valueOfA > valueOfB ? 1 : -1;
                    }
                    return valueOfA < valueOfB ? 1 : -1;
                  }
                  return 0;
                }).slice(_totalPageNum * _totalRowsPerPage, _totalPageNum * _totalRowsPerPage + _totalRowsPerPage).map((prof) => {
                  return (
                    <TableRow key={prof.instructor_id}>
                      <TableCell>{prof.first_name} {prof.last_name}</TableCell>
                      <TableCell>{prof.coverage_days}</TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow style={{height: 50 * emptyRows}}>
                    <TableCell colSpan={2} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, 50]}
              component='div'
              count={filteredInstructors.length}
              rowsPerPage={_totalRowsPerPage}
              page={_totalPageNum}
              previousButton={{'aria-label': 'Previous Page'}}
              nextButton={{'aria-label': 'Next Page'}}
              onPageChange={(event, pageNum) => setTotalPageNum(pageNum)}
              onRowsPerPageChange={(event) => setTotalRowsPerPage(event.target.value)}
            />
          </Paper>
        </div>
      }
    </div>
  );
};

export default (VacationCoverage);
